import './AprobarSOL.css'

import { CloseCircleOutlined, QuestionCircleOutlined, SearchOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip, message } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { rctEntrRendUrl, segConnUrl, solAprobarUrl, solGetAprUrl, solGetNaprUrl, solRechazarUrl, stdGetTipoUrl, usuGetCurrUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'

export const AprobarSOL = ({ page: { code: resource } }) => {
    const dvFechaIni = moment().startOf('month')
    const dvFechaFin = moment().endOf('month')

    const { axiosTnaGet, axiosTnaPost, axiosTnaPut } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyYellowRef = useRef()
    const detSolRef = useRef()
    const [disablePage, setDisablePage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
    const [loadEstado, setLoadEstado] = useState(true)
    const [loadUsuario, setLoadUsuario] = useState(true)
    const [loadConfigER, setLoadConfigER] = useState(false)
    const [loadBusqSol, setLoadBusqSol] = useState(false)
    const [loadAprSol, setLoadAprSol] = useState(false)
    const [loadRecSol, setLoadRecSol] = useState(false)
    const [allGroup, setAllGroup] = useState(false)
    const [receptores, setReceptores] = useState([])
    const [grupos, setGrupos] = useState([])
    const [tiposSol, setTiposSol] = useState([])
    const [areas, setAreas] = useState([])
    const [aprobadores, setAprobadores] = useState([])
    const [estados, setEstados] = useState([])
    const [usuario, setUsuario] = useState(null)
    const [userAprob, setUserAprob] = useState(null)
    const [selReceptor, setSelReceptor] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [pendiente, setPendiente] = useState(false)
    const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
    const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
    const [foTipoSol, setFoTipoSol] = useState(null)
    const [foArea, setFoArea] = useState(null)
    const [foAprobador, setFoAprobador] = useState(null)
    const [foEstado, setFoEstado] = useState(null)
    const [foNumSO, setFoNumSO] = useState(null)
    const [foSave, setFoSave] = useState(null)
    const [foRevertir, setFoRevertir] = useState(false)
    const [solicitudes, setSolicitudes] = useState([])
    const [selSolicitud, setSelSolicitud] = useState(null)
    const [motivoRechazo, setMotivoRechazo] = useState(null)
    const [soTotalDocuments, setSoTotalDocuments] = useState(0)
    const [soPageSize, setSoPageSize] = useState(10)
    const [soCurrentPage, setSoCurrentPage] = useState(1)
    const [openDrawer, setOpenDrawer] = useState(false)

    useEffect(() => {
        setLoadPage(loadTokenConn || loadRcptEmsr || loadEstado || loadUsuario)
    }, [loadTokenConn, loadRcptEmsr, loadEstado, loadUsuario])

    useEffect(() => {
        setDisablePage(loadConfigER || loadBusqSol || loadAprSol || loadRecSol)
    }, [loadConfigER, loadBusqSol, loadAprSol, loadRecSol])

    useEffect(() => {
        setAprobadores(areas.find(p => p.areaCode === foArea)?.reemplazar || [])
        setFoAprobador(null)
    }, [areas, foArea])

    useEffect(() => {
        setUserAprob(aprobadores.find(p => foAprobador && p.userID === foAprobador) || null)
    }, [aprobadores, foAprobador])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const rcptEmsr = async () => {
            const url = usuRcptEmsrUrl(resource)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setReceptores(data.rcpt)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadRcptEmsr(false)
        }

        const stdTipo = async () => {
            const url = stdGetTipoUrl(configType.stdSolicitud)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setEstados(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadEstado(false)
        }

        const currUsuario = async () => {
            const url = usuGetCurrUrl()
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setUsuario(data.data)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadUsuario(false)
        }

        if (!loadTokenConn) {
            rcptEmsr()
            stdTipo()
            currUsuario()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    const setConfigER = useCallback(async (person) => {
        setFoTipoSol(null)
        setFoArea(null)

        if (!person) {
            setTiposSol([])
            setAreas([])
            return
        }

        setLoadConfigER(true)

        const url = rctEntrRendUrl(person.rcptRUC, person.ruc, true, false)
        const [data, err] = await axiosTnaGet(url)

        if (data) {
            const { success, data: config } = data
            if (success) {
                setTiposSol(config.tiposSol || [])
                setAreas(config.areas || [])
            }
        }
        if (err) {
            setTiposSol([])
            setAreas([])
            notifyRedRef.current.handleOpen(err, notifyType.error)
        }

        setLoadConfigER(false)
        // eslint-disable-next-line
    }, [setTiposSol, setAreas])

    const getSol = (sol, index = null) => {
        var nsol = { ...sol, key: sol.id, index }
        if (index === null)
            delete nsol.index
        return nsol
    }

    const getUsu = (usu) => {
        if (!usu)
            return ''
        else if (usu.docIdentidad)
            return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
        else
            return `${usu.userID} - ${usu.userName}`
    }

    const getTitleDrawer = (sol) => sol ? `Solicitud ( ${sol.numSO} )` : ''

    const clearFiltroSO = () => {
        setFoSave(null)
        setSolicitudes([])
        setSelSolicitud(null)
        setSoTotalDocuments(0)
    }

    const handleMotivoRechazo = useCallback((obs) => setMotivoRechazo(obs), [setMotivoRechazo])

    const handlePendiente = (value) => {
        setPendiente(value)
        setFoFechaIni(value ? null : dvFechaIni)
        setFoFechaFin(value ? null : dvFechaFin)
        setFoTipoSol(null)
        setFoArea(null)
        setFoEstado(null)
        clearFiltroSO()
    }

    const handleOpenDrawer = () => {
        setFoRevertir(!pendiente)
        setOpenDrawer(true)
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        setSelSolicitud(null)
        setFoRevertir(false)
    }

    const handleSelReceptor = useCallback((person) => {
        setSelReceptor(person)
        let a_grupos = []
        if (person && person.grupo.length > 0)
            a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
        setGrupos(a_grupos)
        clearFiltroSO()
    }, [setSelReceptor])

    const handleSelGrupo = useCallback((person) => {
        setSelGrupo(person)
        setConfigER(person)
        clearFiltroSO()
    }, [setSelGrupo, setConfigER])

    const handleBuscarSOFecha = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!pendiente && (!foFechaIni || !foFechaFin))
            err = {
                message: msgType.selectFecha,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqSol(true)

        const grupo = allGroup ? '' : selGrupo.ruc
        const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
        const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
        const tipo = foTipoSol || ''
        const area = foArea || ''
        const estado = foEstado || ''
        const aprobador = foAprobador || ''

        const url = solGetAprUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, pendiente, tipo, area, estado, aprobador, resource, 1, soPageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: sols } = data
            setSolicitudes(sols.map((sol, index) => getSol(sol, index)))
            setSoTotalDocuments(totalDocuments)
            setSoCurrentPage(1)
            setFoSave({
                svSelReceptor: selReceptor,
                svGrupo: grupo,
                svFechaIni: fechaIni,
                svFechaFin: fechaFin,
                svPendiente: pendiente,
                svTipo: tipo,
                svArea: area,
                svEstado: estado,
                svAprobador: aprobador,
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqSol(false)
        setLastAccess()
    }

    const handleChangePagSO = async (page, pageSize) => {
        if (!foSave)
            return

        const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svPendiente, svTipo, svArea, svEstado, svAprobador } = foSave

        let err = null
        let data = null

        setLoadBusqSol(true)

        const url = solGetAprUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svPendiente, svTipo, svArea, svEstado, svAprobador, resource, page, pageSize)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { totalDocuments, data: sols } = data
            setSolicitudes(sols.map((sol, index) => getSol(sol, index)))
            setSoTotalDocuments(totalDocuments)
            setSoCurrentPage(page)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqSol(false)
        setLastAccess()
    }

    const handleBuscarSONum = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selGrupo && !allGroup)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        if (!foNumSO || foNumSO.trim().length === 0)
            err = {
                message: msgType.inputNumSO,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadBusqSol(true)

        const url = solGetNaprUrl(selReceptor.ruc, foNumSO, pendiente, foAprobador || '')
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { success, data: sol } = data
            if (success && (allGroup || selGrupo.ruc === sol.grpCode)) {
                setSolicitudes([getSol(sol, 0)])
                setSoTotalDocuments(1)
            }
            else {
                setSolicitudes([])
                setSoTotalDocuments(0)
            }
            setFoSave(null)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadBusqSol(false)
        setLastAccess()
    }

    const handleDetalleSol = (record) => {
        const sol = { ...record }
        setSelSolicitud(sol)
        handleOpenDrawer()
    }

    const handleAprobarSol = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selSolicitud)
            err = {
                message: msgType.selectSolicitud,
                oops: false
            }
        else if (!foRevertir && !selSolicitud.allowPut)
            err = {
                message: msgType.solicitudNoApr,
                oops: false
            }

        if (err) {
            detSolRef.current.showNotify(err, notifyType.warning)
            return
        }

        setLoadAprSol(true)

        const url = solAprobarUrl(selSolicitud.id)
        const [data_api, err_api] = foRevertir ? await axiosTnaPut(url, { aprobador: foAprobador }) : await axiosTnaPost(url, { aprobador: foAprobador })
        data = data_api
        err = err_api

        if (data) {
            setSolicitudes(prev => prev.filter(sol => sol.id !== selSolicitud.id))
            handleCloseDrawer()
            message.success(foRevertir ? msgType.solicitudAprobarRev : msgType.solicitudAprobar);
        }
        if (err)
            detSolRef.current.showNotify(err, notifyType.error)

        setLoadAprSol(false)
        setLastAccess()
    }

    const handleRechazarSol = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selSolicitud)
            err = {
                message: msgType.selectSolicitud,
                oops: false
            }
        else if (!foRevertir && !selSolicitud.allowPut)
            err = {
                message: msgType.solicitudNoRec,
                oops: false
            }
        else if (!foRevertir && (!motivoRechazo || motivoRechazo.length === 0))
            err = {
                message: msgType.inputMotivoRec,
                oops: false
            }

        if (err) {
            detSolRef.current.showNotify(err, notifyType.warning)
            return
        }

        setLoadRecSol(true)

        const url = solRechazarUrl(selSolicitud.id)
        const [data_api, err_api] = foRevertir ? await axiosTnaPut(url, { aprobador: foAprobador }) : await axiosTnaPost(url, { aprobador: foAprobador, observacion: motivoRechazo })
        data = data_api
        err = err_api

        if (data) {
            setSolicitudes(prev => prev.filter(sol => sol.id !== selSolicitud.id))
            handleCloseDrawer()
            message.success(foRevertir ? msgType.solicitudRechazarRev : msgType.solicitudRechazar);
        }
        if (err)
            detSolRef.current.showNotify(err, notifyType.error)

        setLoadRecSol(false)
        setLastAccess()
    }

    const soColumns = [
        {
            title: 'Nº Solicitud',
            dataIndex: 'numSO',
            render: (_, record) =>
                <div className='flex items-center'>
                    <button
                        className='text-blue-600 w-full text-left'
                        onClick={() => handleDetalleSol(record)}
                    >
                        <span>{`${record.numSO}`}</span>
                    </button>
                </div>
        },
        {
            title: 'Fecha Sol.',
            dataIndex: 'fechaSol',
            width: 92,
            render: (_, record) => <span>{formatDate(record.fechaSol)}</span>
        },
        {
            title: '-$-',
            dataIndex: 'monedaISO',
            width: 50,
            align: 'center',
        },
        {
            title: 'Solicitado',
            dataIndex: 'totSol',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.totSol)}</span>
        },
        {
            title: 'Rendido',
            dataIndex: 'totRen',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.totRen)}</span>
        },
        {
            title: 'Saldo',
            dataIndex: 'saldo',
            width: 90,
            align: 'right',
            render: (_, record) => <span>{formatAmount(record.saldo)}</span>
        },
        {
            title: 'Area',
            dataIndex: 'area',
            width: 250,
            responsive: ['lg'],
            render: (_, record) => allGroup ? <span>{`${record.grpCode} ${record.area}`}</span> : <span>{record.area}</span>
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            width: 100,
        },
        {
            title: 'Rindente',
            dataIndex: 'rindente',
            width: 320,
            responsive: ['xl'],
            render: (_, record) => <span>{getUsu(record.rindente ?? record.solicitante)}</span>
        },
    ]

    return (
        <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
            <Drawer
                title={getTitleDrawer(selSolicitud)}
                placement="right"
                onClose={handleCloseDrawer}
                open={openDrawer}
                getContainer={false}
                style={{ position: 'absolute' }}
                bodyStyle={{ padding: 20, }}
                headerStyle={{ padding: 15, }}
                destroyOnClose={true}
                maskClosable={false}
                zIndex={5}
                width={720}
                closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
                extra={
                    <Space>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleAprobarSol}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                        >
                            <Button shape="round" type="primary">
                                {foRevertir ? `Revertir aprobación` : `Aprobar`}
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleRechazarSol}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                        >
                            <Button shape="round" type="primary">
                                {foRevertir ? `Revertir rechazo` : `Rechazar`}
                            </Button>
                        </Popconfirm>
                        {
                            !disablePage &&
                            <Switch checkedChildren="REV" unCheckedChildren="REV" checked={foRevertir} onChange={setFoRevertir} />
                        }
                    </Space>
                }
            >
                {
                    selSolicitud ?
                        <DetalleSol ref={detSolRef} usuario={userAprob ?? usuario} selReceptor={selReceptor} selSolicitud={selSolicitud}
                            aprRevertir={foRevertir} setObservacion={handleMotivoRechazo} /> :
                        <></>
                }
            </Drawer>
            <NotifyRed ref={notifyRedRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage ?
                    <div className='my-5 flex justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex-grow'>
                        <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
                            <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
                            {
                                !allGroup &&
                                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
                            }
                            <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
                        </div>

                        <div className='p-3 flex flex-col ant-common-titania'>
                            <p className="text-xs text-gray-700 mb-2">
                                Busca una solicitud de fondo usando los filtros de fecha de solicitud o número de solicitud.
                            </p>
                            <div className='flex space-x-10'>
                                <div className='flex items-center space-x-2'>
                                    <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                                    <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                                    <Switch checkedChildren="PEND" unCheckedChildren="PEND" checked={pendiente} onChange={handlePendiente} />
                                    <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                                        {
                                            loadConfigER &&
                                            <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                        }
                                        {
                                            (!loadConfigER && aprobadores.length === 0) &&
                                            <Select placeholder='Tipo' value={foTipoSol} allowClear style={{ width: 150 }} onChange={(v) => setFoTipoSol(v || null)}>
                                                {
                                                    tiposSol.map(({ reqCode, reqName }) => (
                                                        <Select.Option key={reqCode} value={reqCode}>{reqName}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            !loadConfigER &&
                                            <Select placeholder='Area' value={foArea} allowClear style={{ width: 200 }} onChange={(v) => setFoArea(v || null)}>
                                                {
                                                    areas.map(({ areaCode, areaName }) => (
                                                        <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            aprobadores.length > 0 &&
                                            <Select placeholder='Reemplazar' value={foAprobador} allowClear style={{ width: 345, fontSize: '12px' }} onChange={(v) => setFoAprobador(v || null)}>
                                                {
                                                    aprobadores.map((usuario) => (
                                                        <Select.Option key={usuario.userID} value={usuario.userID}>{getUsu(usuario)}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                        {
                                            (!pendiente && aprobadores.length === 0) &&
                                            <Select placeholder='Estado' value={foEstado} allowClear style={{ width: 135 }} onChange={(v) => setFoEstado(v || null)}>
                                                {
                                                    estados.map(({ estadoID, nombre }) => (
                                                        <Select.Option key={estadoID} value={nombre}>{nombre}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        }
                                    </div>
                                    {
                                        !disablePage ?
                                            <Tooltip title="Buscar solicitud">
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarSOFecha} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <Input placeholder="Nº de solicitud" onChange={(e) => setFoNumSO(e.target.value)} />
                                    {
                                        !disablePage ?
                                            <Tooltip title="Buscar solicitud">
                                                <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarSONum} />
                                            </Tooltip> :
                                            <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                                    }
                                </div>
                            </div>

                            <div className='lg:max-w-7xl'>
                                {
                                    loadBusqSol ?
                                        <div className='my-5 flex justify-center'>
                                            <Spin tip='Loading....' size='large' />
                                        </div> :
                                        <>
                                            <div className='mt-3 ant-table-titania ant-table-fnaprsol'>
                                                <Table
                                                    columns={soColumns}
                                                    dataSource={solicitudes}
                                                    pagination={false}
                                                    scroll={{ y: 400 }}
                                                    bordered
                                                    size='small'
                                                />
                                            </div>
                                            <div className='mt-2 flex justify-end ant-pagination-titania'>
                                                <Pagination
                                                    defaultCurrent={soCurrentPage}
                                                    total={soTotalDocuments}
                                                    showSizeChanger
                                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} solicitudes`}
                                                    defaultPageSize={soPageSize}
                                                    onChange={handleChangePagSO}
                                                    onShowSizeChange={(_, size) => setSoPageSize(size)}
                                                    disabled={disablePage} />
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
