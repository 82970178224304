import './NewOpe.css'

import { SyncOutlined, CloudUploadOutlined, CloudDownloadOutlined, QuestionCircleOutlined, DeleteFilled } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Row, Select, Spin, Table, Tabs, Tooltip } from 'antd'
import moment from 'moment'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../../hooks/useAxiosCto'
import { opeAdjuntarUrl, opeGetIdUrl, opeModificarUrl, opeRecuperarUrl, opeRegistrarUrl, rctGrpRecurUrl, segConnUrl, storageTCambioUrl, usuGetNumUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { formatDigits } from '../../../../utils/formatUtil'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { downloadFile, viewerFile } from '../../../../utils/fileUtil'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NotifyYellow } from '../../../Msg/NotifyYellow'

var jszip = require('jszip')

export const NewOpe = forwardRef((props, ref) => {
    const { selReceptor, selOperacion, tiposOpe: tiposOpe_all, tiposPago: tiposPago_all, updateOpe, tabKey } = props

    const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const [form] = Form.useForm()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadConfigRT, setLoadConfigRT] = useState(true)
    const [loadUsuario, setLoadUsuario] = useState(true)
    const [componentDisabled, setComponentDisabled] = useState(false)
    const [loadTipoCambio, setLoadTipoCambio] = useState(false)
    const [loadFile, setLoadFile] = useState(false)
    const [loadRegOpe, setLoadRegOpe] = useState(false)
    const [registrado, setRegistrado] = useState(false)
    const [tiposOpe, setTiposOpe] = useState([])
    const [ctasBanRct, setCtasBanRct] = useState([])
    const [bancosRct, setBancosRct] = useState([])
    const [cuentasRct, setCuentasRct] = useState([])
    const [tiposPago, setTiposPago] = useState([])
    const [ctasBanRin, setCtasBanRin] = useState([])
    const [bancosRin, setBancosRin] = useState([])
    const [cuentasRin, setCuentasRin] = useState([])
    const [adjuntos, setAdjuntos] = useState([])
    const [tolTCambio, setTolTCambio] = useState(null)
    const [editTCambioOpe, setEditTCambioOpe] = useState(null)
    const [ctaNacionalOpe, setCtaNacionalOpe] = useState(null)
    const [monedaFun, setMonedaFun] = useState(null)
    const [fmTipoOpe, setFmTipoOpe] = useState(null)
    const [fmFechaOpe, setFmFechaOpe] = useState(null)
    const [fmBancoRct, setFmBancoRct] = useState(null)
    const [fmCuentaRct, setFmCuentaRct] = useState(null)
    const [fmNumTran, setFmNumTran] = useState(null)
    const [fmFechaTran, setFmFechaTran] = useState(null)
    const [fmTipoPago, setFmTipoPago] = useState(null)
    const [fmMonto, setFmMonto] = useState(null)
    const [fmTipoCambio, setFmTipoCambio] = useState(null)
    const [fmTipoCambioSunat, setFmTipoCambioSunat] = useState(null)
    const [fmObserv, setFmObserv] = useState(null)
    const [fmBancoRin, setFmBancoRin] = useState(null)
    const [fmCuentaRin, setFmCuentaRin] = useState(null)
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [activeTabKey, setActiveTabKey] = useState("1")

    useEffect(() => {
        setLoadPage(loadTokenConn || loadConfigRT || loadUsuario)
    }, [loadTokenConn, loadConfigRT, loadUsuario])

    useEffect(() => {
        setComponentDisabled(loadTipoCambio || loadFile || loadRegOpe || registrado)
    }, [loadTipoCambio, loadFile, loadRegOpe, registrado])

    useEffect(() => {
        setRegistrado(selOperacion?.registrado || false)
        setAdjuntos(selOperacion?.adjuntos ? selOperacion.adjuntos.map(p => ({ ...p, key: p.dataOrder })) : [])
    }, [selOperacion])

    useEffect(() => {
        const bancoID = selOperacion.registrado ? selOperacion.bancoID : selOperacion.transaccion?.bancoID
        const aban = groupByBanco(selOperacion, ctasBanRct, ctaNacionalOpe)
        const ban = bancoID ? aban.find(p => p.bancoID === bancoID) : aban.find(() => true)
        setBancosRct(aban)
        setFmBancoRct(ban)
        form.setFieldsValue({
            bancoRct: ban?.bancoID,
        })
    }, [selOperacion, ctasBanRct, ctaNacionalOpe, form])

    useEffect(() => {
        const bancoID = selOperacion.registrado ? selOperacion.bancoID : selOperacion.transaccion?.bancoID
        const numeroCta = selOperacion.registrado ? selOperacion.numeroCta : selOperacion.transaccion?.numeroCta
        const acta = getCuenta(selOperacion, fmBancoRct, ctasBanRct, ctaNacionalOpe)
        const cta = bancoID && fmBancoRct && bancoID === fmBancoRct.bancoID ? acta.find(p => p.numeroCta === numeroCta) : acta.find(() => true)
        setCuentasRct(acta)
        setFmCuentaRct(cta)
        form.setFieldsValue({
            cuentaRct: cta?.numeroCta,
        })
    }, [selOperacion, fmBancoRct, ctasBanRct, ctaNacionalOpe, form])

    useEffect(() => {
        const bancoID = selOperacion.registrado && selOperacion.destino ? selOperacion.destino.bancoID : fmBancoRct?.bancoID
        const aban = groupByBanco(selOperacion, ctasBanRin, false)
        const ban = aban.find(p => p.bancoID === bancoID) || aban.find(() => true)
        if (ban)
            ban.cci = fmBancoRct && ban.bancoID !== fmBancoRct.bancoID
        setBancosRin(aban)
        setFmBancoRin(ban)
        form.setFieldsValue({
            bancoRin: ban?.bancoID,
        })
    }, [selOperacion, ctasBanRin, fmBancoRct, form])

    useEffect(() => {
        const bancoID = selOperacion.registrado && selOperacion.destino ? selOperacion.destino.bancoID : null
        const numeroCta = selOperacion.registrado && selOperacion.destino ? selOperacion.destino.numeroCta : null
        const acta = getCuenta(selOperacion, fmBancoRin, ctasBanRin, false)
        const cta = bancoID && fmBancoRin && bancoID === fmBancoRin.bancoID ? acta.find(p => p.id === numeroCta) : acta.find(() => true)
        if (cta && fmBancoRin?.cci)
            cta.label = `( CCI ) ${cta.cci}`
        setCuentasRin(acta)
        setFmCuentaRin(cta)
        form.setFieldsValue({
            cuentaRin: cta?.numeroCta,
        })
    }, [selOperacion, fmBancoRin, ctasBanRin, form])

    useEffect(() => {
        if (selOperacion) {
            const tpsope = tiposOpe_all ? tiposOpe_all.filter(p => p.codigo === selOperacion.tpoOPCode) : []
            const tpspag = tiposPago_all ? tiposPago_all.filter(p => p.bool1) : []
            const tipoOpe = tpsope.find(() => true)
            const monto = selOperacion.monto
            const rindente = getUsu(selOperacion.rindente)
            const monedaFun = selOperacion.monedaISO === configType.monedaISOPEN
            setTiposOpe(tpsope)
            setTiposPago(tpspag)
            if (selOperacion.registrado) {
                const fechaOpe = moment(selOperacion.fechaOpe)
                const numTran = selOperacion.numTran
                const fechaTran = moment(selOperacion.fechaTran)
                const tipoPago = tpspag.find(p => p.codigo === selOperacion.tipoPagoID)
                const tipoCambio = selOperacion.tipoCambio || 0.0
                const observ = selOperacion.observ
                setFmTipoOpe(tipoOpe)
                setFmFechaOpe(fechaOpe)
                setFmNumTran(numTran)
                setFmFechaTran(fechaTran)
                setFmTipoPago(tipoPago)
                setFmMonto(monto)
                setFmTipoCambio(tipoCambio)
                setFmObserv(observ)
                setMonedaFun(monedaFun)
                form.setFieldsValue({
                    tipoOpe: tipoOpe?.codigo,
                    fechaOpe: fechaOpe,
                    numTran: numTran,
                    fechaTran: fechaTran,
                    tipoPago: tipoPago?.codigo,
                    monto: monto,
                    tipoCambio: tipoCambio,
                    observ: observ,
                    rindente: rindente,
                })
            }
            else {
                const fechaOpe = selOperacion.transaccion ? moment(selOperacion.transaccion.fechaTran) : moment()
                const numTran = selOperacion.transaccion?.numTran ?? selOperacion.numOP
                const fechaTran = selOperacion.transaccion ? moment(selOperacion.transaccion.fechaTran) : moment()
                const tipoPago = selOperacion.transaccion ? tpspag.find(p => p.codigo === selOperacion.transaccion.tipoPagoID) : tpspag.find(() => true)
                const tipoCambio = 0.0
                const observ = null
                setFmTipoOpe(tipoOpe)
                setFmFechaOpe(fechaOpe)
                setFmNumTran(numTran)
                setFmFechaTran(fechaTran)
                setFmTipoPago(tipoPago)
                setFmMonto(monto)
                setFmTipoCambio(tipoCambio)
                setFmObserv(observ)
                setMonedaFun(monedaFun)
                form.setFieldsValue({
                    tipoOpe: tipoOpe?.codigo,
                    fechaOpe: fechaOpe,
                    numTran: numTran,
                    fechaTran: fechaTran,
                    tipoPago: tipoPago?.codigo,
                    monto: monto,
                    tipoCambio: tipoCambio,
                    observ: observ,
                    rindente: rindente,
                })
            }
        }
    }, [selOperacion, tiposOpe_all, tiposPago_all, form])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const configRT = async () => {
            const url = rctGrpRecurUrl(selReceptor?.ruc, selOperacion?.grpCode, 'resource')
            const [data, err] = await axiosTnaGet(url)

            if (data) {
                const { success, data: config } = data
                if (success) {
                    const acta = config?.cuentas?.map(p => ({ ...p, monedaFun: p.monedaISO === configType.monedaISOPEN })) || []
                    const ttcab = config?.tolTCambio || null
                    const edittc = config?.editTCambioOpe || null
                    const ctapen = config?.ctaNacionalOpe || null
                    if (isMounted) {
                        setCtasBanRct(acta)
                        setTolTCambio(ttcab)
                        setEditTCambioOpe(edittc)
                        setCtaNacionalOpe(ctapen)
                    }
                }
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadConfigRT(false)
        }

        const numUsu = async () => {
            const url = usuGetNumUrl(selOperacion?.rindente?.userID)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setCtasBanRin(data.data?.cuentas.map(p => ({ ...p, monedaFun: p.monedaISO === configType.monedaISOPEN })) || [])
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadUsuario(false)
        }

        if (!loadTokenConn) {
            configRT()
            numUsu()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    useEffect(() => {
        let isMounted = true

        const tCambio = async () => {
            isMounted && setLoadTipoCambio(true)

            const fecha = fmFechaOpe.format().split('T')[0]
            const url = storageTCambioUrl(selOperacion.monedaISO, fecha)
            const [data, err] = await axiosCtoGet(url)

            if (isMounted) {
                const tipoCambio = data?.tcVenta || 0.0
                setFmTipoCambio(tipoCambio)
                setFmTipoCambioSunat(tipoCambio)
                form.setFieldsValue({
                    tipoCambio: tipoCambio,
                })
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoCambio(false)
        }
        if (selOperacion && fmFechaOpe && !selOperacion.registrado && monedaFun === false)
            tCambio()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selOperacion, monedaFun, fmFechaOpe])

    useImperativeHandle(
        ref,
        () => ({
            handleSave() {
                if (registrado)
                    handleModOpe()
                else
                    handleRegOpe()
            },
            exactAmount() {
                return selOperacion?.monto === fmMonto
            }
        })
    )

    const refreshOpe = async (ope) => {
        const url = opeGetIdUrl(ope.id)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Operación: ${err.message}`)
        const nren = data?.success ? data.data : null
        return nren
    }

    const onValuesChange = (changedValues, allValues) => {
        const tipoOpe = tiposOpe.find(p => p.codigo === allValues.tipoOpe) || null
        const fechaOpe = allValues.fechaOpe ?? null
        const bancoRct = bancosRct.find(p => p.bancoID === allValues.bancoRct) || null
        const cuentaRct = cuentasRct.find(p => p.numeroCta === allValues.cuentaRct) || null
        const numTran = allValues.numTran ?? null
        const fechaTran = allValues.fechaTran ?? null
        const tipoPago = tiposPago.find(p => p.codigo === allValues.tipoPago) || null
        const monto = allValues.monto ?? null
        const tipoCambio = allValues.tipoCambio ?? null
        const observ = allValues.observ ?? null
        const bancoRin = bancosRin.find(p => p.bancoID === allValues.bancoRin) || null
        const cuentaRin = cuentasRin.find(p => p.numeroCta === allValues.cuentaRin) || null

        setFmTipoOpe(tipoOpe)
        setFmFechaOpe(fechaOpe)
        setFmBancoRct(bancoRct)
        setFmCuentaRct(cuentaRct)
        setFmNumTran(numTran)
        setFmFechaTran(fechaTran)
        setFmTipoPago(tipoPago)
        setFmMonto(monto)
        setFmTipoCambio(tipoCambio)
        setFmObserv(observ)
        setFmBancoRin(bancoRin)
        setFmCuentaRin(cuentaRin)
    }

    const clearFile = () => {
        const input = document.getElementById("file-upload")
        if (input)
            input.value = null
    }

    const groupByBanco = (ope, ctasBan, monFun) => {
        const getBan = (ctab) => ({ bancoID: ctab.bancoID, bancoName: ctab.bancoName, monedaISO: ctab.monedaISO })

        const aban = []
        if (ope && ctasBan) {
            ctasBan.filter(p => p.monedaISO === ope.monedaISO).map(ctab => {
                const ban = aban.find(p => p.bancoID === ctab.bancoID)
                if (!ban)
                    aban.push(getBan(ctab))
                return ctab
            })
            if (monFun)
                ctasBan.filter(p => p.monedaFun).map(ctab => {
                    const ban = aban.find(p => p.bancoID === ctab.bancoID)
                    if (!ban)
                        aban.push(getBan(ctab))
                    return ctab
                })
        }
        return aban
    }

    const getCuenta = (ope, ban, ctasBan, monFun) => {
        const getCta = (cta) => ({ ...cta, label: (ban.cci ? `( CCI ) ${cta.cci}` : `( ${cta.monedaISO} ) ${cta.numeroCta}`), id: (ban.cci ? cta.cci : cta.numeroCta) })

        if (!ope || !ban || !ctasBan)
            return []
        else {
            const acta = []
            ctasBan.filter(p => p.monedaISO === ope.monedaISO && p.bancoID === ban.bancoID).map(cta => acta.push(getCta(cta)))
            if (monFun)
                ctasBan.filter(p => p.monedaISO !== ope.monedaISO && p.bancoID === ban.bancoID && p.monedaFun).map(cta => acta.push(getCta(cta)))
            return acta
        }
    }

    const getUsu = (usu) => {
        if (!usu)
            return ''
        else if (usu.docIdentidad)
            return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
        else
            return `${usu.userID} - ${usu.userName}`
    }

    const handleActiveTabKey = (activeKey) => { setActiveTabKey(activeKey); if (tabKey) tabKey(activeKey) }

    const handleRegOpe = async () => {
        let err = null
        let data = null

        if (componentDisabled)
            return;

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selOperacion)
            err = {
                message: msgType.selectOperacion,
                oops: false
            }
        else if (!selOperacion.grpCode)
            err = {
                message: msgType.selectGrupo,
                oops: false
            }
        else if (!fmTipoOpe)
            err = {
                message: `${msgType.selectTipoOP}`,
                oops: false
            }
        else if (!fmFechaOpe)
            err = {
                message: `${msgType.inputFechaOpe}`,
                oops: false
            }
        else if (!fmNumTran)
            err = {
                message: `${msgType.inputNumTran}`,
                oops: false
            }
        else if (!fmFechaTran)
            err = {
                message: `${msgType.inputFechaTran}`,
                oops: false
            }
        else if (!fmBancoRct)
            err = {
                message: `${msgType.selectBanco}`,
                oops: false
            }
        else if (!fmCuentaRct)
            err = {
                message: `${msgType.selectCuenta}`,
                oops: false
            }
        else if (!fmTipoPago)
            err = {
                message: `${msgType.selectTipoPago}`,
                oops: false
            }
        else if (!fmMonto)
            err = {
                message: `${msgType.inputMontoOpe}`,
                oops: false
            }
        else if (!selOperacion.ingSal && !fmBancoRin)
            err = {
                message: `${msgType.selectBancoR}`,
                oops: false
            }
        else if (!selOperacion.ingSal && !fmCuentaRin)
            err = {
                message: `${msgType.selectCuentaR}`,
                oops: false
            }
        else if (moment().toDate().getTime() < fmFechaOpe.toDate().getTime())
            err = {
                message: `${msgType.koDataFechaOpe}`,
                oops: false
            }
        else if (moment().toDate().getTime() < fmFechaTran.toDate().getTime())
            err = {
                message: `${msgType.koDataFechaTran}`,
                oops: false
            }
        else if (fmMonto <= 0.0 || (selOperacion.ingSal && fmMonto !== selOperacion.monto))
            err = {
                message: `${msgType.koDataMontoOpe}`,
                oops: false
            }
        else if ((!fmTipoCambio || fmTipoCambio <= 0.0) && !monedaFun)
            err = {
                message: `${msgType.inputTCambio}`,
                oops: false
            }
        else if (fmNumTran.trim() === '')
            err = {
                message: `${msgType.koDataNumTran}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        if (tolTCambio !== null && tolTCambio !== undefined && !monedaFun) {
            const tipoSunat = fmTipoCambioSunat ?? 0.0
            if (Math.abs(fmTipoCambio - tipoSunat) > Math.abs(tolTCambio))
                err = {
                    message: `${msgType.rgDataTCambio} ${formatDigits(fmTipoCambio)} <> ${formatDigits(tipoSunat)}`,
                    oops: false
                }
        }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadRegOpe(true)

        const fechaOpe = fmFechaOpe.format().split('T')[0] + 'T00:00:00'
        const numTran = fmNumTran.trim()
        const fechaTran = fmFechaTran.format().split('T')[0] + 'T00:00:00'
        const observ = fmObserv?.trim()
        const destino = selOperacion.ingSal ? null : { banco: fmBancoRin.bancoID, numeroCta: fmCuentaRin.id }
        const tipoCambio = fmTipoCambio === 0.0 ? null : fmTipoCambio

        const url = opeRegistrarUrl()
        const body = {
            rcptNumDoc: selReceptor.ruc, grupo: selOperacion.grpCode, tpoOP: fmTipoOpe.codigo, numOP: selOperacion.numOP,
            fechaOpe, banco: fmBancoRct.bancoID, numeroCta: fmCuentaRct.numeroCta, tipoCambio,
            monto: fmMonto, tipoPago: fmTipoPago.codigo, numTran, fechaTran, observ, destino
        }
        const [data_api, err_api] = await axiosTnaPost(url, body)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: `${msgType.operacionRegistrar} ${data.numOP}`,
                oops: false
            }, notifyType.success)
            const nope = await refreshOpe(data)
            if (nope && updateOpe)
                updateOpe(nope)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadRegOpe(false)
        setLastAccess()
    }

    const handleModOpe = async () => {
        let err = null
        let data = null

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!selOperacion)
            err = {
                message: msgType.selectOperacion,
                oops: false
            }
        else if (!fmNumTran)
            err = {
                message: `${msgType.inputNumTran}`,
                oops: false
            }
        else if (!fmFechaTran)
            err = {
                message: `${msgType.inputFechaTran}`,
                oops: false
            }
        else if (moment().toDate().getTime() < fmFechaTran.toDate().getTime())
            err = {
                message: `${msgType.koDataFechaTran}`,
                oops: false
            }
        else if (fmNumTran.trim() === '')
            err = {
                message: `${msgType.koDataNumTran}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadRegOpe(true)

        const numTran = fmNumTran.trim()
        const fechaTran = fmFechaTran.format().split('T')[0] + 'T00:00:00'
        const observ = fmObserv?.trim()

        const url = opeModificarUrl(selOperacion.id)
        const body = { numTran, fechaTran, observ }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data && data.success) {
            notifyGreenRef.current.handleOpen({
                message: `${msgType.operacionModificar}`,
                oops: false
            }, notifyType.success)
            const nope = await refreshOpe(selOperacion)
            if (nope && updateOpe)
                updateOpe(nope)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadRegOpe(false)
        setLastAccess()
    }

    const handleUpFile = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.fileKoPeso,
                oops: false
            }
        else if (!selOperacion?.registrado)
            err = {
                message: msgType.noRegOperacion,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setLoadFile(true)

        const formData = new FormData()
        formData.append("", file)

        const url = opeAdjuntarUrl(selOperacion.id)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            const nope = await refreshOpe(selOperacion)
            if (nope && updateOpe)
                updateOpe(nope)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        clearFile()
        setLoadFile(false)
        setLastAccess()
    }

    const handleDelFile = async (record) => {
        let err = null
        let data = null

        if (!selOperacion?.registrado)
            err = {
                message: msgType.noRegOperacion,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setCurrentLineFI(record.dataOrder)

        const url = opeAdjuntarUrl(selOperacion.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data && data.success) {
            const nope = await refreshOpe(selOperacion)
            if (nope && updateOpe)
                updateOpe(nope)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = opeRecuperarUrl(selOperacion.id, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex space-x-1 items-center'>
                        <button
                            className='text-blue-600 text-left'
                            disabled={record.dataOrder === currentLineFI}
                            onClick={() => handleDownFile(record, false)}
                        >
                            {`${record.fileName}`}
                        </button>
                        {
                            record.fileType &&
                            <span>{`- ${record.fileType}`}</span>
                        }
                    </div>
                    <div className='flex justify-end items-center'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <>
                                <button onClick={() => handleDownFile(record, true)}>
                                    <CloudDownloadOutlined />
                                </button>
                                {
                                    !record.required &&
                                    <Popconfirm placement="left"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        title={`¿Confirma la operación?`}
                                        onConfirm={() => handleDelFile(record)}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <button className='ml-2'>
                                            <DeleteFilled className='text-red-400' />
                                        </button>
                                    </Popconfirm>
                                }
                            </>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='ml-2' />
                        }
                    </div>
                </div>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                loadPage &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            <div className='ant-tabs-titania ant-common-titania'>
                <Tabs
                    defaultActiveKey="1"
                    hidden={loadPage}
                    activeKey={activeTabKey}
                    onChange={handleActiveTabKey}
                    items={[
                        {
                            label: `Data`,
                            key: '1',
                            children:
                                <>
                                    <Form
                                        layout="vertical" requiredMark
                                        onValuesChange={onValuesChange}
                                        disabled={componentDisabled}
                                        autoComplete="off"
                                        hidden={loadPage}
                                        form={form}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="tipoOpe"
                                                    label="Tipo de operación"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione tipo de operación"
                                                        allowClear>
                                                        {
                                                            tiposOpe.map(({ codigo, nombre }) => (
                                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="fechaOpe"
                                                    label="Fecha de operación"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker placeholder='Ingrese fecha de operación'
                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="bancoRct"
                                                    label="Banco"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione banco"
                                                        allowClear disabled={selOperacion?.ingSal || registrado}>
                                                        {
                                                            bancosRct.map(({ bancoID, bancoName }) => (
                                                                <Select.Option key={bancoID} value={bancoID}>{bancoName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="cuentaRct"
                                                    label="Cuenta bancaria"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione cuenta bancaria"
                                                        allowClear disabled={selOperacion?.ingSal || registrado}>
                                                        {
                                                            cuentasRct.map(({ numeroCta, label }) => (
                                                                <Select.Option key={numeroCta} value={numeroCta}>{label}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="numTran"
                                                    label="Número de transacción"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese nº de transacción'
                                                        size='middle'
                                                        maxLength={50}
                                                        disabled={false}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="fechaTran"
                                                    label="Fecha de transacción"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker placeholder='Ingrese fecha de transacción'
                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                        disabled={false}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="tipoPago"
                                                    label="Tipo de pago"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione tipo de pago"
                                                        allowClear disabled={selOperacion?.ingSal || registrado}>
                                                        {
                                                            tiposPago.map(({ codigo, nombre }) => (
                                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item
                                                    name="monto"
                                                    label={`Monto atendido (${selOperacion?.monedaISO})`}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder='Ingrese monto atendido'
                                                        className='text-xs w-36'
                                                        size='middle'
                                                        maxLength={15}
                                                        bordered={true} precision={2}
                                                        disabled={selOperacion?.ingSal || registrado}
                                                        min={0.0}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    name="tipoCambio"
                                                    label={`Tipo de cambio`}
                                                    rules={[
                                                        {
                                                            required: !monedaFun,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder='Ingrese cambio'
                                                        className='text-xs w-36'
                                                        size='middle'
                                                        maxLength={15}
                                                        bordered={true} precision={5}
                                                        disabled={registrado}
                                                        readOnly={monedaFun || !editTCambioOpe}
                                                        min={0.0} max={100.0}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="observ"
                                                    label="Observación"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        rows={2}
                                                        maxLength={500}
                                                        disabled={false}
                                                        placeholder="Ingrese observación" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="rindente"
                                                    label="Rindente"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        className='text-xs w-36'
                                                        size='middle'
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} hidden={!selOperacion || selOperacion.ingSal} >
                                            <Col span={12}>
                                                <Form.Item
                                                    name="bancoRin"
                                                    label="Banco (Rindente)"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione banco"
                                                        allowClear>
                                                        {
                                                            bancosRin.map(({ bancoID, bancoName }) => (
                                                                <Select.Option key={bancoID} value={bancoID}>{bancoName}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="cuentaRin"
                                                    label="Cuenta bancaria (Rindente)"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Seleccione cuenta bancaria"
                                                        allowClear>
                                                        {
                                                            cuentasRin.map(({ numeroCta, label }) => (
                                                                <Select.Option key={numeroCta} value={numeroCta}>{label}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form >
                                    <div className='flex justify-center mt-4'>
                                        {
                                            loadRegOpe &&
                                            <Tooltip title="Procesando" placement="top">
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<SyncOutlined spin />}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                </>
                        },
                        {
                            label: `Adjunto`,
                            key: '2',
                            disabled: !selOperacion?.registrado,
                            children:
                                <>
                                    {
                                        adjuntos.length > 0 &&
                                        <div className='mb-3 ant-table-titania'>
                                            <Table
                                                columns={fiColumns}
                                                dataSource={adjuntos}
                                                pagination={false}
                                                bordered
                                                size='small'
                                            />
                                        </div>
                                    }
                                    <div className='flex items-center space-x-2'>
                                        <label
                                            htmlFor="file-upload"
                                            className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (loadFile ? " hover:cursor-not-allowed" : "")}
                                        >
                                            <CloudUploadOutlined className="mr-1" />
                                            <span>Subir archivo</span>
                                            <input id="file-upload" name="file-upload" type="file" className="sr-only"
                                                onChange={handleUpFile}
                                                disabled={loadFile}
                                            />
                                        </label>
                                        {
                                            loadFile &&
                                            <div className='flex items-center mr-2 text-indigo-600'>
                                                <SyncOutlined spin />
                                            </div>
                                        }
                                    </div>
                                </>
                        }
                    ]}
                />
            </div>
        </>
    )
})