import './ListaDoc.css'

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { ZoomInOutlined, SearchOutlined, QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Input, Popconfirm, Space, Spin, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { docGetRenUrl, segConnUrl, tsk5Url, tskRecuperarUrl } from '../../../api/apiUrl'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'

var jszip = require('jszip')

export const ListaDoc = forwardRef((props, ref) => {
  const { selReceptor, selRendicion, showNotify, setDocumento } = props

  const { axiosTnaGet, axiosTnaPost } = useAxiosTna()
  const searchInput = useRef(null)
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(false)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadGetDoc, setLoadGetDoc] = useState(false)
  const [loadTask5Add, setLoadTask5Add] = useState(false)
  const [loadTask5Down, setLoadTask5Down] = useState(false)
  const [documentos, setDocumentos] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [taskInterval, setTaskInterval] = useState(false)
  const [task5, setTask5] = useState({ task: null, id: null, first: true })

  useEffect(() => {
    setLoadPage(loadTokenConn || loadGetDoc)
  }, [loadTokenConn, loadGetDoc])

  useEffect(() => {
    setDisablePage(loadTask5Add || loadTask5Down)
  }, [loadTask5Add, loadTask5Down])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const getDoc = async () => {
      isMounted && setLoadGetDoc(true)

      const url = docGetRenUrl(selRendicion.id)
      const [data, err] = await axiosTnaGet(url)

      if (data) {
        const { success, data: adoc } = data
        if (success)
          isMounted && setDocumentos(adoc.map(doc => ({ ...doc, key: doc.id })))
      }
      if (err)
        showNotify(err, notifyType.error)

      isMounted && setLoadGetDoc(false)
    }

    if (!loadTokenConn)
      selRendicion && getDoc()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [selRendicion, loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const t5Get = async () => {
      const url = tsk5Url()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTask5(prev => ({
          ...prev,
          task: data.data,
          id: data.data?.id ?? prev.id,
          first: false
        }))
      if (err)
        console.log(`Task: ${err.message}`)
    }

    if (!loadTokenConn && (task5.first || (!task5.task && task5.id) || (task5.task && !task5.task.complete)))
      t5Get()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [taskInterval, loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const interval = setInterval(() => {
      isMounted && setTaskInterval(prev => !prev)
    }, 5000)

    return () => {
      clearInterval(interval)
      isMounted = false
    }
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
  const handleReset = (clearFilters, confirm) => { clearFilters(); confirm() }

  const handleSeleccionarDoc = () => {
    const doc = selectedRows && selectedRows.length === 1 ? documentos.find(p => p.id === selectedRows[0].id) : null
    if (doc && setDocumento)
      setDocumento(doc)
  }

  const handleTask5_Add = async () => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }

    if (err) {
      showNotify(err, notifyType.warning)
      return
    }

    setLoadTask5Add(true)

    const url = tsk5Url()
    const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, numRD: selRendicion.numRD })
    data = data_api
    err = err_api

    if (data) {
      setTask5(prev => ({ ...prev, task: null, id: data.id }))
      showNotify({
        message: msgType.fileRequest,
        oops: false
      }, notifyType.success)
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadTask5Add(false)
    setLastAccess()
  }

  const handleTask5_Down = async ({ id, file: record }) => {
    let err = null

    setLoadTask5Down(true)

    const url = tskRecuperarUrl(id)
    const [data, err_api] = await axiosTnaGet(url)
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
        setTask5(prev => ({ ...prev, task: null, id: null }))
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      showNotify(err, notifyType.error)

    setLoadTask5Down(false)
    setLastAccess()
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Ingrese palabra`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className='text-xs'
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Buscar
          </Button>
          <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90, }}
          >
            Reiniciar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'emisor') {
        const { numeroDoc, nomRazSoc } = record.emisor
        return numeroDoc.toLowerCase().includes(value.toLowerCase()) || nomRazSoc.toLowerCase().includes(value.toLowerCase())
      }
      else if (dataIndex === 'documento') {
        const { serie, numero } = record
        const documento = `${serie}-${numero}`
        return documento.toLowerCase().includes(value.toLowerCase())
      }
      else if (dataIndex === 'fechaDoc') {
        const { fechaDoc } = record
        const fecha = moment(fechaDoc).format('DD/MM/yyyy')
        return fecha.includes(value.toLowerCase())
      }
      else
        return false
    },
  })

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(newSelectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: false,
    }),
    type: 'radio',
  }

  const doColumns = [
    {
      title: 'Emisor',
      dataIndex: 'emisor',
      render: (_, record) => record.emisor ? <span>{`${record.emisor.numeroDoc} - ${record.emisor.nomRazSoc}`}</span> : <></>,
      ...getColumnSearchProps('emisor'),
    },
    {
      title: 'Tipo Doc.',
      dataIndex: 'tipoDocumento',
      width: 145,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.tipoDocumento.length - b.tipoDocumento.length,
    },
    {
      title: 'Número Doc.',
      dataIndex: 'documento',
      width: 120,
      render: (_, record) =>
        <div className='flex items-center'>
          <button
            className='text-blue-600 text-left'
            onClick={() => setDocumento && setDocumento(record)}
          >
            <span>{`${record.serie}-${record.numero}`}</span>
          </button>
        </div>,
      ...getColumnSearchProps('documento'),
    },
    {
      title: 'Fecha Doc.',
      dataIndex: 'fechaDoc',
      width: 110,
      render: (_, record) => <span>{formatDate(record.fechaDoc)}</span>,
      ...getColumnSearchProps('fechaDoc'),
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 60,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      }),
      sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 90,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.total)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.total - b.total,
    },
  ]

  return (
    <>
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <>
            <div className="flex flex-row flex-wrap space-x-3 mb-3">
              <div className="flex items-center text-sm text-indigo-700">
                {
                  selectedRows && selectedRows.length === 1 ?
                    <div className='flex items-center cursor-pointer'
                      onClick={handleSeleccionarDoc}>
                      <Tooltip title={"Seleccionar documento"} placement='right'>
                        <ZoomInOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                      </Tooltip>
                      Seleccionar
                    </div> :
                    <div className='flex items-center cursor-not-allowed'>
                      <ZoomInOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                      Seleccionar
                    </div>
                }
              </div>
              <div className="flex items-center text-sm text-indigo-700">
                {
                  (!task5.id || task5.task?.message) ?
                    <>
                      {
                        !disablePage ?
                          <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleTask5_Add}
                            placement="right"
                            okText="Sí"
                            cancelText="No"
                          >
                            <div className='flex items-center cursor-pointer'>
                              <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              {`Descargar`}
                            </div>
                          </Popconfirm> :
                          <div className='flex items-center cursor-not-allowed'>
                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                            {
                              loadTask5Add &&
                              <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                              </svg>
                            }
                            {`Descargar`}
                          </div>
                      }
                    </> :
                    <>
                      {
                        task5.task?.file ?
                          <div className='flex items-center text-xs'>
                            <Tooltip title="Descargar archivo" placement='right'>
                              <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                            </Tooltip>
                            {
                              loadTask5Down &&
                              <SyncOutlined spin className='mr-2' />
                            }
                            <button
                              className='text-blue-600'
                              disabled={disablePage}
                              onClick={() => handleTask5_Down(task5.task)}
                            >
                              {task5.task.file.fileName}
                            </button>
                          </div> :
                          <div className='flex items-center text-xs'>
                            <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                            <SyncOutlined spin className='mr-2' />
                            {`Preparando archivo`}
                          </div>
                      }
                    </>
                }
              </div>
              {
                task5.task?.message &&
                <div className='flex items-center'>
                  <p className="text-xs text-red-700">
                    {task5.task?.message}
                  </p>
                </div>
              }
            </div>
            <div className='ant-table-titania ant-table-rowsel-titania ant-pagination-titania ant-table-listadoc'>
              <Table
                rowSelection={rowSelection}
                columns={doColumns}
                dataSource={documentos}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} documentos`
                }}
                showSizeChanger
                scroll={{ y: 390 }}
                bordered
                size='small'
              />
            </div>
          </>
      }
    </>
  )
})
