import {
    ScheduleOutlined, CloudUploadOutlined, DeleteFilled, CheckCircleTwoTone,
    FileProtectOutlined, SyncOutlined, SaveOutlined, QuestionCircleOutlined,
    CloseCircleTwoTone, DeleteOutlined, CloudDownloadOutlined, TrademarkCircleOutlined
} from '@ant-design/icons'
import { ShieldCheckIcon, ArrowUpOnSquareIcon } from '@heroicons/react/20/solid'
import { Button, Collapse, DatePicker, InputNumber, Popconfirm, Select, Spin, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {
    billDataUrl,
    cpeAdjuntarUrl, cpeEliminarUrl, cpeGetNumUrl, cpeRecuperarUrl, cpeRegistrarUrl,
    cpeValidateUrl, ocpGetNumUrl, rcpGetNocUrl, storageRUCUrl, storageTCambioUrl
} from '../../../../api/apiUrl'
import { useAxiosCto } from '../../../../hooks/useAxiosCto'
import { useAxiosDne } from '../../../../hooks/useAxiosDne'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { useForm } from '../../../../hooks/useForm'
import { configType } from '../../../../types/configType'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { downloadFile, jsonBase64, toBase64, viewerFile } from '../../../../utils/fileUtil'
import { formatAmount, formatCamelCase, formatDate, formatDigits } from '../../../../utils/formatUtil'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { NotifyGreen } from '../../../Msg/NotifyGreen'
import { NotifyRed } from '../../../Msg/NotifyRed'
import { NotifyYellow } from '../../../Msg/NotifyYellow'

import './LoadCpeReg.css'

var jszip = require('jszip')

export const LoadCpeReg = (props) => {
    const { rcpt, emsr, ocp, rcps, cpe: cpeDB, updateOcp, tiposDocCpe: configDocCpe, tiposTrip: configTrib } = props

    const { axiosDnePut } = useAxiosDne()
    const { axiosCtoGet, axiosCtoPost } = useAxiosCto()
    const { axiosTnaGet, axiosTnaPost, axiosTnaPut, axiosTnaDelete } = useAxiosTna()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [receipts, setReceipts] = useState([])
    const [tiposDocCpe, setTiposDocCpe] = useState([])
    const [attachedFiles, setAttachedFiles] = useState([])
    const [tipoDocCpe, setTipoDocCpe] = useState(null)
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [fileName, setFileName] = useState('')
    const [documentXML, setDocumentXML] = useState(null)
    const [documentJSON, setDocumentJSON] = useState(null)
    const [disablePage, setDisablePage] = useState(false)
    const [loadCargarCpe, setLoadCargarCpe] = useState(false)
    const [loadCargarFile, setLoadCargarFile] = useState(false)
    const [loadValidarCpe, setLoadValidarCpe] = useState(false)
    const [loadValidarRUC, setLoadValidarRUC] = useState(false)
    const [loadGuardarCpe, setLoadGuardarCpe] = useState(false)
    const [loadEliminarCpe, setLoadEliminarCpe] = useState(false)
    const [collActiveKeyRUC, setCollActiveKeyRUC] = useState([])
    const [collActiveKeyDET, setCollActiveKeyDET] = useState([])
    const [{ factRUC }, handleInputChangeFactRUC, resetFactRUC] = useForm({ factRUC: '' })
    const [factNomRazSoc, setFactNomRazSoc] = useState('')
    const [{ numDET }, handleInputChangeNumDET, resetNumDET] = useForm({ numDET: '' })
    const [fechaPago, setFechaPago] = useState(null)
    const [rucVal, setRUCVal] = useState(false)
    const [cpeVal, setCpeVal] = useState(false)
    const [cpeCto, setCpeCto] = useState(null)
    const [cpeSave, setCpeSave] = useState(false)

    useEffect(() => {
        setDisablePage(loadCargarCpe || loadCargarFile || loadValidarCpe || loadValidarRUC || loadGuardarCpe || loadEliminarCpe || currentLineFI)
    }, [loadCargarCpe, loadCargarFile, loadValidarCpe, loadValidarRUC, loadGuardarCpe, loadEliminarCpe, currentLineFI])

    useEffect(() => {
        if (ocp && ocp.cpeAttach) {
            const { cpeAttach: files } = ocp
            files.sort(sortAttach)
            setAttachedFiles(files.map(file => ({
                ...file,
                key: file.line
            })))
        }
        if (rcps) {
            rcps.sort(sortRcp)
            setReceipts(rcps.map(rc => ({
                ...rc,
                key: rc.numRE,
                saldo: rc.total - rc.totCpe
            })))
        }
    }, [ocp, rcps])

    useEffect(() => {
        if (configDocCpe) {
            setTiposDocCpe(configDocCpe.filter(p => !(ocp?.cpeTipoDoc) || p.tipoDocumentoID === ocp.cpeTipoDoc))
            setTipoDocCpe(configDocCpe.find(p => ocp?.cpeTipoDoc && p.tipoDocumentoID === ocp.cpeTipoDoc)?.tipoComprobanteID)
        }
    }, [ocp, configDocCpe])

    useEffect(() => {
        let isMounted = true

        const getDoc = async () => {
            setLoadCargarCpe(true)

            const url = cpeGetNumUrl(rcpt.ruc, emsr.ruc, cpeDB.tipoComprobanteID, cpeDB.serie, cpeDB.numero)
            const [data, err] = await axiosTnaGet(url)

            if (data && isMounted) {
                const { success, data: cpe } = data
                if (success)
                    refreshCpe(cpe)
            }
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)
            setLoadCargarCpe(false)
        }

        if (cpeDB)
            getDoc()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [emsr, cpeDB])

    const sortRcp = (a, b) => {
        if (a.numRE > b.numRE)
            return 1
        if (a.numRE < b.numRE)
            return -1
        else
            return -1
    }

    const sortAttach = (a, b) => {
        if (a.line > b.line)
            return 1
        if (a.line < b.line)
            return -1
        else
            return -1
    }

    const getSTributoMoneda = (nombreTributo) => {
        if (nombreTributo === configType.tributoDET.codigo)
            return ` ${configType.monedaISOPEN}`
        else
            return ''
    }

    const getBaseImp = (atrib, config) => {
        let baseImp = 0.0
        if (atrib && ocp.baseImp)
            for (const trib of atrib) {
                const cfgTrib = config.find(p => p.tributoID === trib.nombreTributo)
                if (cfgTrib && cfgTrib.baseImp === true)
                    baseImp += trib.montoTributo
            }
        return baseImp
    }

    const clearAttach = (line) => {
        const input = document.getElementById(`file-upload-${line}`)
        if (input)
            input.value = null
    }

    const clearFile = () => {
        setFileName('')
        setDocumentXML(null)
        setDocumentJSON(null)
        setCpeCto(null)
        setCpeVal(false)
        if (!cpeDB)
            setReceipts(prev => {
                const dataN = prev.map(rc => ({ ...rc, saldo: rc.total - rc.totCpe }))
                dataN.sort(sortRcp)
                return dataN
            })
        setAttachedFiles(prev => {
            const dataN = prev.map(fi => ({ ...fi, fileName: null, contentType: null }))
            dataN.sort(sortAttach)
            return dataN
        })
        const input = document.getElementById("file-upload")
        if (input)
            input.value = null
        setCollActiveKeyRUC([])
        clearFactoring()
        setCollActiveKeyDET([])
        clearAutodetraccion()
    }

    const clearFactoring = () => {
        resetFactRUC()
        setFactNomRazSoc('')
        setRUCVal(false)
    }

    const clearAutodetraccion = () => {
        resetNumDET()
        setFechaPago(null)
    }

    const estadoCpe = (attach) => {
        if (attach) {
            const xml = attach.find(at => at.fileType === configType.attachLnXML)
            const json = attach.find(at => at.fileType === configType.attachLnJSON)
            if (xml && json)
                return true
        }
        return false
    }

    const attachFile = async (cpe, base64, mime, name, typeLn, setDocument) => {
        let err = null
        let data = null

        const url = cpeAdjuntarUrl(cpe.id, typeLn, true)
        const [data_api, err_api] = await axiosTnaPut(url, { fileName: name, document: base64, contentType: mime })
        data = data_api
        err = err_api

        if (data) {
            setCpeCto(prev => {
                const nattach = prev.attach ? [...prev.attach, { ...data }] : [{ ...data }]
                return { ...prev, attach: nattach }
            })
            setDocument(null)
        }

        return [data, err]
    }

    const refreshOcp = async () => {
        let err = null
        let data = null

        const url = ocpGetNumUrl(rcpt.ruc, ocp.numOC)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { success, data: oc } = data
            if (success)
                return oc
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)
        return null
    }

    const refreshRcp = async () => {
        let err = null
        let data = null

        const url = rcpGetNocUrl(rcpt.ruc, ocp.numOC)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            const { success, data: rcs } = data
            if (success) {
                const nrcs = rcs.filter(rc => {
                    return receipts.find(rcp => rcp.numRE === rc.numRE)
                })
                nrcs.sort(sortRcp)
                setReceipts(nrcs.map(rc => {
                    return {
                        ...rc,
                        key: rc.numRE,
                        saldo: rc.total - rc.totCpe
                    }
                }))
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)
    }

    const refreshCpe = (cpe) => {
        const data = {
            id: cpe.id,
            grupo: cpe.grupo,
            tipoComprobanteID: cpe.tipoComprobanteID,
            serie: cpe.serie,
            numero: cpe.numero,
            fechaEmision: cpe.fechaEmision,
            monedaID: cpe.monedaISO,
            tipoCambio: cpe.tipoCambio,
            totalValorVenta: cpe.base,
            importeTotal: cpe.total,
            estado: cpe.estado,
            allowPut: cpe.allowPut,
            allowDelete: cpe.allowDelete,
            adquirente: {
                tipoDocumentoID: configType.tipoDocIdeRUC,
                documento: rcpt.ruc,
                denominacion: rcpt.name
            },
            emisor: null,
            formaPago: null,
            montoTributo: null,
            stributo: null,
            otraInfo: null,
            attach: cpe.attach,
            factoring: cpe.factoring,
            observacion: cpe.observacion
        }
        if (cpe.emisor) {
            data.emisor = {
                tipoDocumentoID: configType.tipoDocIdeRUC,
                documento: cpe.emisor.ruc,
                denominacion: cpe.emisor.name
            }
        }
        if (cpe.formaPago)
            data.formaPago = {
                formaPagoID: cpe.formaPago.formaPagoID,
                montoPendPago: cpe.formaPago.montoPendPago,
                aCuota: cpe.formaPago.cuota
            }
        if (cpe.tributo)
            data.montoTributo = {
                valor: null,
                aTributo: cpe.tributo
            }
        if (cpe.stributo) {
            const updSTributo = (stributo, tributo) => {
                data.stributo = {
                    ...tributo,
                    label: stributo.nombre,
                }
            }
            if (cpe.stributo.nombreTributo === configType.tributoDET.codigo)
                updSTributo(configType.tributoDET, cpe.stributo)
            else if (cpe.stributo.nombreTributo === configType.tributoRET.codigo)
                updSTributo(configType.tributoRET, cpe.stributo)
        }
        if (cpe.ordenCompra)
            data.otraInfo = {
                ordenCompra: cpe.ordenCompra
            }

        setCpeSave(true)
        setCpeCto(data)
        setCpeVal(cpe.validado)

        if (cpe.origen && cpe.origen.recepcion)
            setReceipts(cpe.origen.recepcion.map(rc => {
                return {
                    key: rc.numRE,
                    numRE: rc.numRE,
                    saldo: rc.totRcp
                }
            }))

        if (cpe.factoring) {
            handleInputChangeFactRUC({
                target: {
                    name: 'factRUC',
                    value: cpe.factoring.ruc
                }
            })
            setFactNomRazSoc(cpe.factoring.nomRazSoc)
            setRUCVal(cpe.factoring.validado)
        }

        if (cpe.stributo?.autodetraccion) {
            handleInputChangeNumDET({
                target: {
                    name: 'numDET',
                    value: cpe.stributo.autodetraccion.numConstancia
                }
            })
            setFechaPago(moment(cpe.stributo.autodetraccion.fechaPago))
        }

        if (cpe.attach) {
            setAttachedFiles(prev => {
                const dataN = prev.map(fi => {
                    const dataC = cpe.attach.find(fc => fc.dataOrder === fi.line)
                    return { ...fi, fileName: dataC?.fileName || null, contentType: dataC?.contentType || null }
                })
                dataN.sort(sortAttach)
                return dataN
            })
        }
    }

    const handleChangeCollapseRUC = (key) => {
        setCollActiveKeyRUC(key)
        if (!cpeSave && (!key || key.length === 0))
            clearFactoring()
    }

    const handleChangeCollapseDET = (key) => {
        setCollActiveKeyDET(key)
        if (!cpeSave && (!key || key.length === 0))
            clearAutodetraccion()
    }

    const handleChangeSaldo = (value, record) => setReceipts(prev => {
        const rcs = prev.filter(rcp => rcp.numRE !== record.numRE)
        const data = [...rcs, { ...record, saldo: value }]
        data.sort(sortRcp)
        return data
    })

    const handleChangeFile1 = async ({ target: { files } }) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearFile()
            return;
        }

        const file = files[0]

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.regCpeNoFileS,
                oops: false
            }
        else if (file.type !== configType.fileTypeXML)
            err = {
                message: msgType.fileKoXML,
                oops: false
            }
        else if (!tipoDocCpe)
            err = {
                message: msgType.regCpeNoTipoDocCpe,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearFile()
            return
        }

        setFileName(file.name)
        setLoadCargarFile(true)

        const formData = new FormData()
        formData.append("", file)

        const url = billDataUrl(tipoDocCpe)
        const [data_api, err_api] = await axiosDnePut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            try {
                const base64 = await toBase64(file)
                const json64 = jsonBase64(data)

                const updSTributo = (stributo, tributo, categoriaID, factor, funcional) => {
                    data.stributo = {
                        nombreTributo: stributo.codigo,
                        montoTributo: tributo.montoTributo,
                        categoriaID,
                        factor,
                        label: stributo.nombre,
                        funcional,
                    }
                }
                if (data.detraccion) {
                    const stributo = configType.tributoDET
                    const { detraccion: tributo } = data
                    updSTributo(stributo, tributo, tributo.bienServID, Math.round(((tributo.porcentaje ?? 0.0) / 100.0) * 10000) / 10000, true)
                }
                else if (data.retencion) {
                    const stributo = configType.tributoRET
                    const { retencion: tributo } = data
                    updSTributo(stributo, tributo, tributo.regimenID, tributo.factor, false)
                }
                else
                    data.stributo = null
                data.grupo = ocp.grupo

                if (data.tipoComprobanteID === configType.tipoCpeBV) {
                    data.formaPago = {
                        formaPagoID: configType.condPagoContado,
                        montoPendPago: null,
                        aCuota: null,
                    }
                }
                else if (data.tipoComprobanteID === configType.tipoCpeRH) {
                    data.totalValorVenta = data.montoTributo?.aTributo[0]?.montoBase
                }

                const baseImp = getBaseImp(data.montoTributo?.aTributo || [], configTrib)
                let monto = ocp.totTax ? data.importeTotal : (data.totalValorVenta + baseImp)
                const rcs = receipts.map(rc => ({ ...rc }))
                for (const rc of rcs) {
                    const min = Math.min(monto, rc.saldo)
                    if (!(rc.saldo > monto && rc.saldo - monto <= Math.abs(ocp.redondeo)))
                        rc.saldo = min
                    monto = monto - min
                    if (monto === 0)
                        break
                }
                rcs.sort(sortRcp)

                if (data.monedaID && data.fechaEmision && data.monedaID !== configType.monedaISOPEN) {
                    const fecha = data.fechaEmision.split('T')[0]
                    const url = storageTCambioUrl(data.monedaID, fecha)
                    const [data_api, err_api] = await axiosCtoGet(url)
                    err = err_api
                    if (data_api) {
                        data.tipoCambio = data_api.tcVenta
                    }
                    if (err_api) {
                        data.tipoCambio = null
                        console.log(`Tipo de cambio: ${err_api}`)
                    }
                }
                else {
                    data.tipoCambio = null
                }

                setReceipts(rcs)
                setCpeCto(data)
                setDocumentXML(base64)
                setDocumentJSON(json64)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadCargarFile(false)
        setLastAccess()
    }

    const handleChangeFile2 = async ({ target: { files } }, record) => {
        let err = null
        let data = null

        if (!files || files.length === 0) {
            clearAttach(record.line)
            return;
        }

        const file = files[0]
        const fileExt = file.name.split('.')

        if (file.size > configType.attachMaxSize)
            err = {
                message: msgType.regCpeNoFileS,
                oops: false
            }
        else if (!fileExt || fileExt.length < 2 || (`.${fileExt[1].toLowerCase()}` !== record.fileExt.toLowerCase() && configType.fileExtAny !== record.fileExt))
            err = {
                message: msgType.regCpeNoFileE,
                oops: false
            }
        else if (!cpeCto)
            err = {
                message: msgType.regCpeNoData,
                oops: false
            }
        else if (!cpeCto.allowPut)
            err = {
                message: `${msgType.regCpeNoAdjuntar}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            clearAttach(record.line)
            return
        }

        setCurrentLineFI(record.line)

        const formData = new FormData()
        formData.append("", file)

        const url = cpeAdjuntarUrl(cpeCto.id, record.line)
        const [data_api, err_api] = await axiosTnaPut(url, formData)
        data = data_api
        err = err_api

        if (data) {
            const { fileName, contentType } = data
            setAttachedFiles(prev => {
                const dataO = prev.filter(fi => fi.line !== record.line)
                const dataN = [...dataO, { ...record, fileName, contentType }]
                dataN.sort(sortAttach)
                return dataN
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const handleValidateFile = async () => {
        let err = null
        let data = null

        const { emisor: { documento: emisorID }, tipoComprobanteID, serie, numero, fechaEmision, importeTotal, totalValorVenta } = cpeCto

        if (!emisorID)
            err = {
                message: `${msgType.regCpeNoTag} { emisor.documento }.`,
                oops: false
            }
        else if (!tipoComprobanteID)
            err = {
                message: `${msgType.regCpeNoTag} { tipoComprobanteID }.`,
                oops: false
            }
        else if (!serie)
            err = {
                message: `${msgType.regCpeNoTag} { serie }.`,
                oops: false
            }
        else if (!numero)
            err = {
                message: `${msgType.regCpeNoTag} { numero }.`,
                oops: false
            }
        else if (!fechaEmision)
            err = {
                message: `${msgType.regCpeNoTag} { fechaEmision }.`,
                oops: false
            }
        else if (!importeTotal)
            err = {
                message: `${msgType.regCpeNoTag} { importeTotal }.`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadValidarCpe(true)

        const tipoCpe = tipoComprobanteID !== configType.tipoCpeRH ? tipoComprobanteID : configType.tipoCpeRH_Cto
        const monto = tipoComprobanteID !== configType.tipoCpeRH ? importeTotal : totalValorVenta

        const url = cpeValidateUrl()
        const [data_api, err_api] = await axiosCtoPost(url, {
            suscriptorID: configType.ctoSuscriptor, emisorID, tipoComprobanteID: tipoCpe, serie, numero, fechaEmision, importeTotal: monto
        })
        data = data_api
        err = err_api

        if (data) {
            const { eCpeID } = data
            if (eCpeID === configType.cpeAceptado)
                setCpeVal(true)
            else if (eCpeID === configType.cpeNoExiste)
                notifyYellowRef.current.handleOpen({
                    message: msgType.apiCtoCpeNoExiste,
                    oops: false
                }, notifyType.warning)
            else if (eCpeID === configType.cpeAnulado)
                notifyYellowRef.current.handleOpen({
                    message: msgType.apiCtoCpeAnulado,
                    oops: false
                }, notifyType.warning)
            else if (eCpeID === null)
                notifyYellowRef.current.handleOpen({
                    message: msgType.apiCtoSinRespuesta,
                    oops: false
                }, notifyType.warning)
            else
                notifyYellowRef.current.handleOpen({
                    message: msgType.apiCtoOtraRespuesta,
                    oops: false
                }, notifyType.warning)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadValidarCpe(false)
        setLastAccess()
    }

    const handleValidateRUC = async () => {
        let err = null
        let data = null

        if (!factRUC)
            err = {
                message: msgType.regCpeNoRUC,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadValidarRUC(true)

        const url = storageRUCUrl(factRUC)
        const [data_api, err_api] = await axiosCtoGet(url)
        data = data_api
        err = err_api

        if (data) {
            setFactNomRazSoc(data.nomRazSoc)
            setRUCVal(true)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadValidarRUC(false)
        setLastAccess()
    }

    const handleGuardarCpe = async () => {
        let err = null
        let data = null

        const {
            emisor: { documento: emsrNumDoc, tipoDocumentoID: emsrTipoDocID },
            tipoComprobanteID, serie, numero, fechaEmision, monedaID, tipoCambio,
            importeTotal: total,
            adquirente: { documento: rcptNumDoc },
            totalValorVenta: base, formaPago,
            montoTributo: { aTributo }, stributo,
            otraInfo
        } = cpeCto
        const ordenCompra = otraInfo?.ordenCompra

        const origen = {
            numOC: ocp.numOC,
            aRecepcion: receipts.map((rcp) => ({
                numRE: rcp.numRE,
                totRcp: rcp.saldo
            }))
        }

        const factoring = collActiveKeyRUC && collActiveKeyRUC.length > 0 ? {
            ruc: factRUC,
            nomRazSoc: factNomRazSoc,
            validado: rucVal
        } : null

        const autodetraccion = collActiveKeyDET && collActiveKeyDET.length > 0 ? {
            numConstancia: numDET,
            fechaPago: fechaPago ? fechaPago.format().split('T')[0] + 'T00:00:00' : null,
        } : null
        if (stributo)
            stributo.autodetraccion = autodetraccion

        const totalAplicar = origen.aRecepcion.reduce((a, b) => a + b.totRcp, 0)
        const baseImp = getBaseImp(aTributo || [], configTrib)

        const getSTributo = (tributo) => {
            if (tributo === configType.tributoDET)
                return 'detraccion'
            else if (tributo === configType.tributoRET)
                return 'retencion'
            else if (tributo === configType.tributoPER)
                return 'percepcion'
            else
                return null
        }

        if (!emsrNumDoc || !emsrTipoDocID)
            err = {
                message: `${msgType.regCpeNoTag} { emisor.documento }.`,
                oops: false
            }
        else if (!tipoComprobanteID)
            err = {
                message: `${msgType.regCpeNoTag} { tipoComprobanteID }.`,
                oops: false
            }
        else if (!serie)
            err = {
                message: `${msgType.regCpeNoTag} { serie }.`,
                oops: false
            }
        else if (!numero)
            err = {
                message: `${msgType.regCpeNoTag} { numero }.`,
                oops: false
            }
        else if (!fechaEmision)
            err = {
                message: `${msgType.regCpeNoTag} { fechaEmision }.`,
                oops: false
            }
        else if (!rcptNumDoc)
            err = {
                message: `${msgType.regCpeNoTag} { adquirente.documento }.`,
                oops: false
            }
        else if (!total && total !== 0.0)
            err = {
                message: `${msgType.regCpeNoTag} { importeTotal }.`,
                oops: false
            }
        else if (!base && base !== 0.0)
            err = {
                message: `${msgType.regCpeNoTag} { totalValorVenta }.`,
                oops: false
            }
        else if (!formaPago)
            err = {
                message: `${msgType.regCpeNoTag} { formaPago }.`,
                oops: false
            }
        else if (formaPago.aCuota && formaPago.aCuota.length === 0)
            err = {
                message: `${msgType.regCpeNoTag} { formaPago.aCuota }.`,
                oops: false
            }
        else if (formaPago.aCuota && formaPago.aCuota.find(cuota => !cuota.cuotaID))
            err = {
                message: `${msgType.regCpeNoTag} { formaPago.aCuota.cuotaID }.`,
                oops: false
            }
        else if (formaPago.aCuota && formaPago.aCuota.find(cuota => !cuota.montoPago && cuota.montoPago !== 0.0))
            err = {
                message: `${msgType.regCpeNoTag} { formaPago.aCuota.montoPago }.`,
                oops: false
            }
        else if (formaPago.aCuota && formaPago.aCuota.find(cuota => !cuota.fechaPago))
            err = {
                message: `${msgType.regCpeNoTag} { formaPago.aCuota.fechaPago }.`,
                oops: false
            }
        else if (!aTributo || aTributo.length === 0)
            err = {
                message: `${msgType.regCpeNoTag} { montoTributo.aTributo }.`,
                oops: false
            }
        else if (aTributo.find(tributo => !tributo.montoTributo && tributo.montoTributo !== 0.0))
            err = {
                message: `${msgType.regCpeNoTag} { montoTributo.aTributo.montoTributo }.`,
                oops: false
            }
        else if (aTributo.find(tributo => !tributo.nombreTributo))
            err = {
                message: `${msgType.regCpeNoTag} { montoTributo.aTributo.nombreTributo }.`,
                oops: false
            }
        else if (stributo && !stributo.nombreTributo)
            err = {
                message: `${msgType.regCpeNoTag} { stributo.nombreTributo }.`,
                oops: false
            }
        else if (stributo && !stributo.montoTributo && stributo.montoTributo !== 0.0)
            err = {
                message: `${msgType.regCpeNoTag} { ${getSTributo(stributo.nombreTributo)}.montoTributo }.`,
                oops: false
            }
        else if (factoring && !rucVal)
            err = {
                message: `${msgType.regCpeValRUC}`,
                oops: false
            }
        else if (factoring && !factoring.ruc)
            err = {
                message: `${msgType.regCpeNoTag} { factoring.ruc }.`,
                oops: false
            }
        else if (factoring && !factoring.nomRazSoc)
            err = {
                message: `${msgType.regCpeNoTag} { factoring.nomRazSoc }.`,
                oops: false
            }
        else if (autodetraccion && !autodetraccion.numConstancia)
            err = {
                message: `${msgType.regCpeDetNoNum}`,
                oops: false
            }
        else if (autodetraccion && !autodetraccion.fechaPago)
            err = {
                message: `${msgType.regCpeDetNoFecha}`,
                oops: false
            }
        else if (!origen)
            err = {
                message: `${msgType.regCpeNoTag} { origen }.`,
                oops: false
            }
        else if (!origen.numOC)
            err = {
                message: `${msgType.regCpeNoTag} { origen.numOC }.`,
                oops: false
            }
        else if (!origen.aRecepcion || origen.aRecepcion.length === 0)
            err = {
                message: `${msgType.regCpeNoTag} { origen.aRecepcion }.`,
                oops: false
            }
        else if (origen.aRecepcion.find(rcp => !rcp.numRE))
            err = {
                message: `${msgType.regCpeNoTag} { origen.aRecepcion.numRE }.`,
                oops: false
            }
        else if (origen.aRecepcion.find(rcp => !rcp.totRcp))
            err = {
                message: `${msgType.regCpeNoTag} { origen.aRecepcion.totRcp }.`,
                oops: false
            }
        else if (`${emsrNumDoc}` !== emsr.ruc)
            err = {
                message: `${msgType.regCpeNoEmisor}`,
                oops: false
            }
        else if (rcptNumDoc !== rcpt.ruc)
            err = {
                message: `${msgType.regCpeNoReceptor}`,
                oops: false
            }
        else if (receipts.find(rcp => rcp.saldo <= 0.0 || rcp.saldo > rcp.total - rcp.totCpe))
            err = {
                message: `${msgType.regCpeRcpNoSaldo}`,
                oops: false
            }
        else if (ocp.monedaISO !== monedaID)
            err = {
                message: `${msgType.regCpeNoMonedaOC}`,
                oops: false
            }
        else if (ocp.totTax && Math.abs(totalAplicar - total) > Math.abs(ocp.redondeo))
            err = {
                message: `${msgType.regCpeRcpMonto}`,
                oops: false
            }
        else if (!ocp.totTax && Math.abs(totalAplicar - (base + baseImp)) > Math.abs(ocp.redondeo))
            err = {
                message: `${msgType.regCpeRcpMonto}`,
                oops: false
            }
        else if (ocp.valNumOC && (!ordenCompra || ocp.numOC !== ordenCompra))
            err = {
                message: `${msgType.regCpeFNoNumOC}`,
                oops: false
            }
        else if (ocp.valCpe && !cpeVal)
            err = {
                message: `${msgType.regCpeValCpe}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadGuardarCpe(true)

        const url = cpeRegistrarUrl()
        const [data_api, err_api] = await axiosTnaPost(url, {
            rcptNumDoc, tipoComprobanteID, serie, numero, fechaEmision, tipoCambio, base, total,
            validado: cpeVal, ordenCompra, formaPago: { ...formaPago, montoPendPago: formaPago.montoPendPago || 0 },
            aTributo, stributo, factoring, origen
        })
        data = data_api
        err = err_api

        if (data) {
            const getName = (fileExt) => (`${emsrNumDoc}-${tipoComprobanteID}-${serie}-${numero}${fileExt}`)
            setCpeCto(prev => { return { ...prev, id: data.id, estado: data.estado, allowPut: true, allowDelete: true } })
            try {
                await attachFile(data, documentXML, configType.fileTypeXML, getName(configType.fileExtXML), configType.attachLnXML, setDocumentXML)
                await attachFile(data, documentJSON, configType.contentTypeJSON, getName(configType.fileExtJSON), configType.attachLnJSON, setDocumentJSON)
            }
            catch (error) {
                console.log(error)
            }
            setCpeSave(true)
            notifyGreenRef.current.handleOpen({
                message: msgType.regCpeSaveOK,
                oops: false
            }, notifyType.success)
            updateOcp(await refreshOcp())
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadGuardarCpe(false)
        setLastAccess()
    }

    const handleEliminarCpe = async () => {
        let err = null
        let data = null

        const {
            id,
            emisor: { documento: emisorID, tipoDocumentoID: emsrTipoDocID },
            tipoComprobanteID, serie, numero, adquirente: { documento: receptorID },
            allowDelete
        } = cpeCto

        if (!emisorID || !emsrTipoDocID)
            err = {
                message: `${msgType.regCpeNoTag} { emisor.documento }.`,
                oops: false
            }
        else if (!tipoComprobanteID)
            err = {
                message: `${msgType.regCpeNoTag} { tipoComprobanteID }.`,
                oops: false
            }
        else if (!serie)
            err = {
                message: `${msgType.regCpeNoTag} { serie }.`,
                oops: false
            }
        else if (!numero)
            err = {
                message: `${msgType.regCpeNoTag} { numero }.`,
                oops: false
            }
        else if (!receptorID)
            err = {
                message: `${msgType.regCpeNoTag} { adquirente.documento }.`,
                oops: false
            }
        else if (`${emisorID}` !== emsr.ruc)
            err = {
                message: `${msgType.regCpeNoEmisor}`,
                oops: false
            }
        else if (receptorID !== rcpt.ruc)
            err = {
                message: `${msgType.regCpeNoReceptor}`,
                oops: false
            }
        else if (!allowDelete)
            err = {
                message: `${msgType.regCpeNoEliminar}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadEliminarCpe(true)

        const url = cpeEliminarUrl(id)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data) {
            setCpeSave(false)
            clearFile()
            notifyGreenRef.current.handleOpen({
                message: msgType.regCpeDeleteOK,
                oops: false
            }, notifyType.success)
            await refreshRcp()
            const oc = await refreshOcp()
            updateOcp(oc)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadEliminarCpe(false)
        setLastAccess()
    }

    const handleDownFile = async (record) => {
        let err = null
        let data = null

        setCurrentLineFI(record.line)

        const url = cpeRecuperarUrl(cpeCto.id, record.line)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const handleDeleteFile = async (record) => {
        let err = null
        let data = null

        if (!cpeCto.allowPut)
            err = {
                message: `${msgType.regCpeNoExcluir}`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setCurrentLineFI(record.line)

        const url = cpeAdjuntarUrl(cpeCto.id, record.line)
        const [data_api, err_api] = await axiosTnaDelete(url)
        data = data_api
        err = err_api

        if (data) {
            setAttachedFiles(prev => {
                const dataO = prev.filter(fi => fi.line !== record.line)
                const dataN = [...dataO, { ...record, fileName: null, contentType: null }]
                dataN.sort(sortAttach)
                return dataN
            })
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const reColumns = [
        {
            title: 'Nº Ingreso',
            dataIndex: 'numRE',
            render: (text, record) => cpeSave ? <span>{text}</span> :
                <span
                    className={`cursor-pointer hover:text-indigo-500${record.total - record.totCpe === record.saldo ? '' : ' text-indigo-500'}`}
                    onClick={() => handleChangeSaldo(record.total - record.totCpe, record)}
                >
                    {text}
                </span>
        },
        {
            title: 'Monto',
            dataIndex: 'saldo',
            width: 130,
            align: 'right',
            render: (_, record) => cpeSave ? <span>{formatAmount(record.saldo)}</span> :
                <div>
                    <InputNumber
                        className='text-xs w-full'
                        size='small' bordered={true} precision={2}
                        defaultValue={record.saldo}
                        value={record.saldo}
                        onChange={(value) => handleChangeSaldo(value, record)} />
                </div>
        },
    ]

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'name',
            render: (_, record) =>
                <div className='flex items-center'>
                    {
                        record.required &&
                        <Tooltip placement="right" title="Archivo requerido">
                            <TrademarkCircleOutlined size='small' className='text-red-600 mr-1' />
                        </Tooltip>
                    }
                    {
                        record.fileName ?
                            <button
                                className='text-blue-600 w-full text-left'
                                disabled={record.line === currentLineFI}
                                onClick={() => handleDownFile(record)}
                            >
                                {`${record.name} (${record.fileExt})`}
                            </button> :
                            <span className='w-full text-left'>
                                {`${record.name} (${record.fileExt})`}
                            </span>
                    }
                    <div className='flex justify-end'>
                        {
                            record.line !== currentLineFI && !record.fileName &&
                            <label
                                htmlFor={`file-upload-${record.line}`}
                                className="flex items-center relative cursor-pointer mr-1"
                            >
                                <ArrowUpOnSquareIcon className="h-3 w-3" aria-hidden="true" />
                                <input id={`file-upload-${record.line}`} name={`file-upload-${record.line}`} type="file" className="sr-only"
                                    onChange={e => handleChangeFile2(e, record)}
                                />
                            </label>
                        }
                        {
                            record.line !== currentLineFI && record.fileName &&
                            <>
                                <button className='mr-2' onClick={() => handleDownFile(record)}>
                                    <CloudDownloadOutlined />
                                </button>
                                <Popconfirm placement="left"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    title={`¿Confirma la operación?`}
                                    onConfirm={() => handleDeleteFile(record)}
                                    okText="Sí"
                                    cancelText="No"
                                >
                                    <button className='mr-1'>
                                        <DeleteFilled className='text-red-400' />
                                    </button>
                                </Popconfirm>
                            </>
                        }
                        {
                            record.line === currentLineFI &&
                            <Spin size='small' className='mr-1' />
                        }
                    </div>
                </div>
        },
    ]

    return (
        <>
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            {
                !loadCargarCpe &&
                <>
                    <div className='loadcpereg-tabla-re mb-2'>
                        <Table
                            columns={reColumns}
                            dataSource={receipts}
                            pagination={false}
                            bordered
                            size='small'
                        />
                    </div>
                    {
                        !cpeSave &&
                        < div className="flex text-sm items-center text-gray-600">
                            {
                                cpeVal &&
                                <label className="flex items-center p-0.5 relative rounded-md bg-white font-medium text-indigo-600"
                                >
                                    <FileProtectOutlined className="mr-1" />
                                </label>
                            }
                            {
                                !cpeCto &&
                                <div className='flex items-center space-x-2 mr-2 loadcpereg-archivo'>
                                    {
                                        !loadCargarFile &&
                                        <Select placeholder='Tipo' allowClear style={{ width: 100 }} value={tipoDocCpe} onChange={(v) => setTipoDocCpe(v || null)}>
                                            {
                                                tiposDocCpe.map(({ tipoDocumentoID, tipoComprobanteID, nombre }) => (
                                                    <Select.Option key={`${tipoDocumentoID}-${tipoComprobanteID}`} value={tipoComprobanteID}>{nombre}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    }
                                    <label
                                        htmlFor="file-upload"
                                        className={"flex items-center p-0.5 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500" + (!tipoDocCpe ? " hover:cursor-not-allowed" : "")}
                                    >
                                        <Tooltip title="Cargar comprobante electrónico" placement='top'>
                                            <CloudUploadOutlined className="mr-1" />
                                        </Tooltip>
                                        <span>CPE</span>
                                        <input id="file-upload" name="file-upload" type="file" className="sr-only"
                                            disabled={!tipoDocCpe}
                                            onChange={handleChangeFile1}
                                        />
                                    </label>
                                </div>
                            }
                            {
                                cpeCto && !cpeVal && !loadValidarCpe &&
                                <button
                                    className="flex items-center p-0.5 mr-2 relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    onClick={handleValidateFile}
                                    disabled={disablePage}
                                >
                                    <ScheduleOutlined className="mr-1" />
                                    <span>Validar</span>
                                </button>
                            }
                            {
                                loadValidarCpe &&
                                <div className='flex items-center mr-2 text-indigo-600'>
                                    <SyncOutlined spin />
                                </div>
                            }
                            <p className="p-0.5 mr-2 text-xs">{fileName}</p>
                            {
                                cpeVal &&
                                <Tooltip title="Comprobante validado">
                                    <CheckCircleTwoTone className='mr-1' twoToneColor="#52c41a" />
                                </Tooltip>
                            }
                            {
                                cpeCto &&
                                <button
                                    className={'flex items-center' + (disablePage ? ' cursor-not-allowed' : '')}
                                    onClick={() => clearFile()}
                                    disabled={disablePage}
                                >
                                    <DeleteFilled className='text-red-400' />
                                </button>
                            }
                        </div>
                    }
                    {
                        cpeSave &&
                        <>
                            <div className="flex text-sm items-center text-gray-600">
                                {
                                    cpeCto &&
                                    <p className="p-0.5 mr-2 text-xs font-medium text-gray-900">
                                        {`${cpeCto.emisor?.documento}-${cpeCto.tipoComprobanteID}-${cpeCto.serie}-${cpeCto.numero}`}
                                    </p>
                                }
                                {
                                    cpeVal ?
                                        <Tooltip title="Comprobante validado">
                                            <CheckCircleTwoTone className='mr-2' twoToneColor="#52c41a" />
                                        </Tooltip> :
                                        <Tooltip title="Comprobante sin validar">
                                            <CloseCircleTwoTone className='mr-2' twoToneColor="#eb2f96" />
                                        </Tooltip>
                                }
                                {
                                    cpeCto &&
                                    <>
                                        <p className="p-0.5 text-xs text-indigo-600">
                                            {`${cpeCto.estado}`}
                                        </p>
                                        {
                                            !estadoCpe(cpeCto.attach) &&
                                            <Tooltip placement="left" title="Elimine y vuelva a cargar">
                                                <p className="p-0.5 text-xs text-red-700">
                                                    {`- ERROR`}
                                                </p>
                                            </Tooltip>
                                        }
                                    </>
                                }
                            </div>
                            {
                                cpeCto.observacion &&
                                < div className="flex items-center text-red-600">
                                    <p className='p-0.5 text-xs'><span className='text-gray-900'>{`Mensaje: `}</span>{`${cpeCto.observacion}`}</p>
                                </div>
                            }
                        </>
                    }
                </>
            }
            {
                loadCargarCpe &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' />
                </div>
            }
            {
                cpeCto &&
                <div className='loadcpereg-data-cpe mt-2 text-xs font-normal text-gray-900'>
                    {
                        cpeCto.emisor &&
                        <>
                            <p><span>{`RUC: `}</span>{`${cpeCto.emisor.documento}`}</p>
                            <p><span>{`Emisor: `}</span>{`${cpeCto.emisor.denominacion}`}</p>
                        </>
                    }
                    <p><span>{`Nº de comprobante: `}</span>{`${cpeCto.tipoComprobanteID}-${cpeCto.serie}-${cpeCto.numero}`}</p>
                    <p><span>{`Fecha de emisión: `}</span>{`${formatDate(cpeCto.fechaEmision)}`}</p>
                    {
                        cpeCto.adquirente &&
                        <>
                            <p><span>{`RUC: `}</span>{`${cpeCto.adquirente.documento}`}</p>
                            <p><span>{`Receptor: `}</span>{`${cpeCto.adquirente.denominacion}`}</p>
                        </>
                    }
                    <p><span>{`${formatCamelCase(rcpt?.grpName ?? '')}: `}</span>{`${cpeCto.grupo}`}</p>
                    <p><span>{`Moneda: `}</span>{`${cpeCto.monedaID}`}</p>
                    {
                        cpeCto.tipoCambio &&
                        <p><span>{`Tipo de cambio: `}</span>{`${formatDigits(cpeCto.tipoCambio)}`}</p>
                    }
                    <p><span>{`Total valor venta: `}</span>{`${formatAmount(cpeCto.totalValorVenta)}`}</p>
                    {
                        cpeCto.montoTributo?.aTributo &&
                        <ul>
                            {
                                cpeCto.montoTributo.aTributo.map((tributo, index) => (
                                    <li key={index}><span>{`${tributo.nombreTributo}: `}</span>{`${formatAmount(tributo.montoTributo)}`}</li>
                                ))
                            }
                        </ul>
                    }
                    <p><span>{`Importe total: `}</span>{`${formatAmount(cpeCto.importeTotal)}`}</p>
                    {
                        cpeCto.stributo &&
                        <p><span>{`${cpeCto.stributo.label}: `}</span>{`${formatAmount(cpeCto.stributo.montoTributo)}${getSTributoMoneda(cpeCto.stributo.nombreTributo)} [${cpeCto.stributo.categoriaID} ${cpeCto.stributo.factor * 100}%]`}</p>
                    }
                    <p><span>{`Forma de pago: `}</span>{`${cpeCto.formaPago?.formaPagoID ?? ''}`}</p>
                    {
                        !!(cpeCto.formaPago?.montoPendPago) &&
                        <p><span>{`Monto neto pendiente: `}</span>{`${formatAmount(cpeCto.formaPago.montoPendPago)}`}</p>
                    }
                    {
                        cpeCto.formaPago?.aCuota &&
                        <ul>
                            {
                                cpeCto.formaPago.aCuota.filter(p => p.cuotaID !== '').map((cuota, index) => (
                                    <li key={index}><span>{`${cuota.cuotaID} `}</span>{`| ${formatDate(cuota.fechaPago)} | ${formatAmount(cuota.montoPago)}`}</li>
                                ))
                            }
                        </ul>
                    }
                    {
                        cpeCto.otraInfo?.ordenCompra &&
                        <p><span>{`Orden de compra: `}</span>{`${cpeCto.otraInfo.ordenCompra}`}</p>
                    }
                </div>
            }
            {
                loadCargarFile &&
                <div className='my-5 flex justify-center'>
                    <Spin tip='Loading....' />
                </div>
            }
            {
                cpeCto &&
                <>
                    {
                        (!cpeSave || (factNomRazSoc && factNomRazSoc.length > 0)) &&
                        <div className='loadcpereg-collapse-fact mt-1' >
                            <Collapse className='text-xs' ghost defaultActiveKey={[]} activeKey={collActiveKeyRUC} onChange={handleChangeCollapseRUC}>
                                <Collapse.Panel header="Factoring" key="1">
                                    <div className="flex">
                                        <span className="inline-flex items-center p-1 text-xs rounded-l rounded-b-none border border-r-0 border-b-0 border-gray-300 bg-gray-100 px-3 text-gray-500">
                                            RUC
                                        </span>
                                        <input
                                            type="text"
                                            name="factRUC"
                                            id="factRUC"
                                            autoComplete='factRUC'
                                            className="block text-xs pl-1 mr-1 w-28 rounded-none rounded-r rounded-b-none border border-b-0 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={factRUC}
                                            onChange={handleInputChangeFactRUC}
                                            readOnly={rucVal || cpeSave}
                                        />
                                        {
                                            !rucVal && !loadValidarRUC && !cpeSave &&
                                            <button
                                                className="flex items-center relative rounded-md bg-white font-medium text-indigo-600"
                                                disabled={disablePage}
                                                onClick={handleValidateRUC}
                                            >
                                                <Tooltip title="Validar RUC">
                                                    <ShieldCheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </Tooltip>
                                            </button>
                                        }
                                        {
                                            loadValidarRUC &&
                                            <div className='flex items-center text-indigo-600'>
                                                <SyncOutlined spin />
                                            </div>
                                        }
                                        {
                                            rucVal &&
                                            <div className='flex items-center'>
                                                <Tooltip title="RUC validado">
                                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                </Tooltip>
                                            </div>
                                        }
                                        {
                                            !rucVal && cpeSave &&
                                            <div className='flex items-center'>
                                                <Tooltip title="RUC sin validar">
                                                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex">
                                        <span className="inline-flex items-center w-full h-6 p-0.5 text-xs rounded-none rounded-b rounded-r border border-gray-300 bg-gray-100 px-3 text-gray-500">
                                            {factNomRazSoc}
                                        </span>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    }
                    {
                        ((!cpeSave && (cpeCto.stributo?.funcional ?? false)) || (numDET && numDET.length > 0 && fechaPago)) &&
                        <div className='loadcpereg-collapse-fact mt-1' >
                            <Collapse className='text-xs' ghost defaultActiveKey={[]} activeKey={collActiveKeyDET} onChange={handleChangeCollapseDET}>
                                <Collapse.Panel header="Autodetracción" key="1">
                                    <div className="flex">
                                        <span className="inline-flex items-center w-32 p-1 text-xs rounded-tl border border-r-0 border-b-0 border-gray-300 bg-gray-100 px-3 text-gray-500">
                                            Nª de constancia
                                        </span>
                                        <input
                                            type="text"
                                            name="numDET"
                                            id="numDET"
                                            autoComplete='numDET'
                                            className="block text-xs w-36 pl-1 mr-1 rounded-tr border border-b-0 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                            value={numDET}
                                            onChange={handleInputChangeNumDET}
                                            readOnly={cpeSave}
                                            maxLength='20'
                                        />
                                    </div>
                                    <div className="flex">
                                        <span className="inline-flex items-center w-32 p-1 text-xs rounded-bl border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500">
                                            Fecha de pago
                                        </span>
                                        {
                                            cpeSave ?
                                                <input
                                                    type="text"
                                                    name="fechaPago"
                                                    id="fechaPago"
                                                    autoComplete='fechaPago'
                                                    className="block text-xs w-36 pl-1 mr-1 rounded-br border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                                    value={formatDate(fechaPago.format())}
                                                    readOnly={cpeSave}
                                                /> :
                                                <DatePicker
                                                    placeholder='Fecha'
                                                    className='w-36'
                                                    size='middle'
                                                    value={fechaPago}
                                                    readOnly={cpeSave}
                                                    onChange={(d,) => setFechaPago(d ?? null)} />
                                        }
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    }
                    <div className='loadcpereg-save flex justify-center mt-3'>
                        {
                            !disablePage &&
                            (
                                !cpeSave ?
                                    <Tooltip title="Guardar comprobante" placement="bottom">
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`${cpeVal ? (ocp?.numOC ?? '') : 'CPE sin validar'} ¿Confirma la operación?`}
                                            onConfirm={handleGuardarCpe}
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<SaveOutlined />}
                                            />
                                        </Popconfirm>
                                    </Tooltip> :
                                    <Tooltip title="Eliminar comprobante" placement="bottom">
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={`¿Confirma la operación?`}
                                            onConfirm={handleEliminarCpe}
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<DeleteOutlined />}
                                            />
                                        </Popconfirm>
                                    </Tooltip>
                            )
                        }
                        {
                            disablePage &&
                            <Tooltip title="Procesando" placement="bottom">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<SyncOutlined spin />}
                                />
                            </Tooltip>
                        }
                    </div>
                </>
            }
            {
                cpeSave &&
                <div className='loadcpereg-tabla-fi mt-3'>
                    <Table
                        columns={fiColumns}
                        dataSource={attachedFiles}
                        pagination={false}
                        bordered
                        size='small'
                    />
                </div>
            }
        </>
    )
}
