import './DetalleDoc.css'

import { CloudDownloadOutlined, CheckCircleTwoTone, CloudUploadOutlined, ScheduleOutlined, SyncOutlined, SearchOutlined } from '@ant-design/icons'
import { Modal, Select, Spin, Table, Tabs, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { docGetIdUrl, docRecuperarUrl } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatDigits, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { ListaDoc } from '../List/ListaDoc'

var jszip = require('jszip')

export const DetalleDoc = (props) => {
    const { selReceptor, selRendicion, showNotify } = props

    const { axiosTnaGet } = useAxiosTna()
    const [loadDocumento, setLoadDocumento] = useState(false)
    const [camposDoc, setCamposDoc] = useState([])
    const [camposCtb, setCamposCtb] = useState([])
    const [camposAsg, setCamposAsg] = useState([])
    const [documentos, setDocumentos] = useState([])
    const [asignaciones, setAsignaciones] = useState([])
    const [ccosto, setCcosto] = useState([])
    const [adjuntos, setAdjuntos] = useState([])
    const [dbDocumento, setDbDocumento] = useState(null)
    const [selDocumento, setSelDocumento] = useState(null)
    const [selAsignacion, setSelAsignacion] = useState(null)
    const [currentLineFI, setCurrentLineFI] = useState(null)
    const [activeTabKey, setActiveTabKey] = useState("1")
    const [totalDoc, setTotalDoc] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        if (documentos && documentos.length > 0 && selRendicion) {
            let monto = 0.0
            documentos.forEach(doc => {
                monto += doc.monto;
            })
            setTotalDoc(`${documentos.length} doc. y ${formatAmount(monto)} ${selRendicion.monedaISO}`)
        }
        else
            setTotalDoc(null)
    }, [documentos, selRendicion])

    useEffect(() => {
        if (selRendicion) {
            if (selRendicion.documentos) {
                const adoc = selRendicion.documentos.map((doc, index) => ({ ...doc, key: index }))
                const sdoc = adoc.length > 0 ? adoc[adoc.length - 1] : null
                setDocumentos(adoc)
                setSelDocumento(sdoc)
            }
        }
    }, [selRendicion])

    useEffect(() => {
        const getCampoDoc = (ren, doc) => {
            const campos_1 = [
                { label: 'Emisor', value: doc.emisor ? `${doc.emisor.numeroDoc} - ${doc.emisor.nomRazSoc}` : null, validatedEmsr: doc.emisor?.validated, stdoContrbEmsr: doc.emisor?.stdoContrb, condDomicEmsr: doc.emisor?.condDomic },
                { label: 'Tipo de documento', value: doc.tipoDocumento },
                { label: 'Nº de documento', value: `${doc.serie}-${doc.numero}`, validatedCPE: doc.validatedCPE, uploadedXML: doc.uploadedXML },
                { label: 'Fecha de documento', value: formatDate(doc.fechaDoc) },
                { label: 'Moneda', value: doc.monedaISO, detail: (doc.tipoCambio ? ` (TC: ${formatDigits(doc.tipoCambio)})` : '') },
            ]
            const campos_2 = doc.tributos ? doc.tributos.map(p => (
                { label: `Base ${p.nombreTributo}`, value: `${formatAmount(p.montoBase)}` }
            )) : null
            const campos_3 = doc.tributos ? doc.tributos.filter(p => p.montoTributo !== 0.0).map(p => (
                { label: p.nombreTributo, value: `${formatAmount(p.montoTributo)}`, detail: (p.factor !== null ? ` (${formatAmount(p.factor * 100.0)}%)` : '') }
            )) : null
            const campos_4 = [
                { label: 'Otros cargos', value: doc.cargo ? `${formatAmount(doc.cargo)} ` : null },
                { label: 'Otros descuentos', value: doc.descuento ? `${formatAmount(doc.descuento)} ` : null },
                { label: 'Importe total', value: `${formatAmount(doc.total)} ` },
                { label: 'Monto redondeo', value: doc.redondeo !== 0.0 ? `${formatAmount(doc.redondeo)}` : null },
                { label: 'Monto rendición', value: doc.origen && doc.origen.montoRen !== doc.total ? `${formatAmount(doc.origen.montoRen)} ${ren.monedaISO}` : null, detail: (doc.tCambioRen ? ` (TC: ${formatDigits(doc.tCambioRen)})` : '') },
                { label: 'Descripción', value: doc.descrip ? doc.descrip : null },
                { label: 'Fecha de registro', value: formatTime(doc.fechaReg) },
                { label: 'Usuario de registro', value: doc.usuarioReg },
            ]
            const campos = [...campos_1]
            if (campos_2)
                campos.push(...campos_2)
            if (campos_3)
                campos.push(...campos_3)
            campos.push(...campos_4)
            return campos.filter(p => p.value)
        }

        const getCampoCtb = (ren, doc) => {
            const campos = [
                //{ label: 'Fecha contable', value: doc.fechaDoc !== doc.fechaCtb ? formatDate(doc.fechaCtb) : null },
                { label: 'Fecha contable', value: formatDate(doc.fechaCtb) },
                { label: getSTributoName(doc.stributo?.nombreTributo), value: doc.stributo ? `${formatAmount(doc.stributo.montoTributo)}` : null, detail: doc.stributo ? `(Categoría: ${doc.stributo.categoriaID}` + (doc.stributo.autodetraccion ? `, Nº Constancia: ${doc.stributo.autodetraccion.numConstancia}, Pago: ${formatDate(doc.stributo.autodetraccion.fechaPago)}` : '') + ')' : null },
                { label: 'Elemento de gasto', value: `${doc.egastoCode} - ${doc.egasto}` },
            ]
            return campos.filter(p => p.value)
        }

        setCamposDoc(selRendicion && dbDocumento ? getCampoDoc(selRendicion, dbDocumento) : [])
        setCamposCtb(selRendicion && dbDocumento ? getCampoCtb(selRendicion, dbDocumento) : [])
    }, [selRendicion, dbDocumento])

    useEffect(() => {
        const getCampo = (asg) => {
            const campos = [
                { label: 'Monto', value: `${formatAmount(asg.monto)} ${asg.monedaISO} ` },
                { label: 'Porcentaje', value: `${formatAmount(asg.porcentaje * 100.0)} % ` },
            ]
            return campos.filter(p => p.value)
        }

        setCamposAsg(selAsignacion ? getCampo(selAsignacion) : [])
    }, [selAsignacion])

    useEffect(() => {
        if (dbDocumento) {
            if (dbDocumento.asignaciones) {
                const aasg = dbDocumento.asignaciones.map((asg, index) => ({
                    ...asg,
                    key: index,
                    label: `Asignación ${asg.line + 1} `,
                    monedaISO: dbDocumento.monedaISO,
                }))
                const sasg = aasg.length > 0 ? aasg[aasg.length - 1] : null
                setAsignaciones(aasg)
                setSelAsignacion(sasg)
                if (sasg) {
                    const acco = sasg.ccosto.map((cco, index) => ({
                        ...cco,
                        key: index
                    }))
                    setCcosto(acco)
                }
            }
            if (dbDocumento.adjuntos)
                setAdjuntos(dbDocumento.adjuntos.map((att, index) => ({
                    ...att,
                    key: index
                })))
        }
        else {
            setAsignaciones([])
            setSelAsignacion(null)
            setCcosto([])
            setAdjuntos([])
            setActiveTabKey('1')
        }
    }, [dbDocumento])

    useEffect(() => {
        let isMounted = true

        const getDoc = async () => {
            isMounted && setLoadDocumento(true)

            const url = docGetIdUrl(selDocumento.documentoID)
            const [data, err] = await axiosTnaGet(url)

            if (data) {
                const { success, data: doc } = data
                if (success)
                    isMounted && setDbDocumento({ ...doc, key: doc.id })
            }
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadDocumento(false)
        }

        if (selDocumento)
            getDoc()
        else
            isMounted && setDbDocumento(null)

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selDocumento])

    const getSTributoName = (nombreTributo) => {
        if (nombreTributo === configType.tributoDET.codigo)
            return configType.tributoDET.nombre
        else if (nombreTributo === configType.tributoRET.codigo)
            return configType.tributoRET.nombre
        else
            return ''
    }

    const getClassName = (index, lista) => {
        if (index === 0)
            return 'sm:flex'
        else if (index === lista.length - 1)
            return 'py-0.5 sm:flex'
        else
            return 'pt-0.5 sm:flex'
    }

    const handleChangeDocumento = (value, adoc) => {
        const sdoc = adoc.find(p => p.documentoID === value)
        setOpenModal(false)
        setSelDocumento(sdoc)
        setActiveTabKey('1')
    }

    const handleChangeAsignacion = (value, aasg) => {
        const sasg = aasg.find(p => p.line === value)
        const acco = sasg ? sasg.ccosto.map((cco, index) => ({ ...cco, key: index })) : []
        setSelAsignacion(sasg)
        setCcosto(acco)
    }

    const handleDownFile = async (record, download) => {
        let err = null
        let data = null

        setCurrentLineFI(record.dataOrder)

        const url = docRecuperarUrl(selDocumento.documentoID, null, record.dataOrder)
        const [data_api, err_api] = await axiosTnaGet(url)
        data = data_api
        err = err_api

        if (data) {
            try {
                const { document } = data
                const zip = new jszip()
                const zresult = await zip.loadAsync(document, { base64: true })
                const unzip = await zresult.file(record.fileName).async('base64')
                if (!download && record.contentType === configType.contentTypePDF)
                    viewerFile(unzip, record.contentType, record.fileName)
                else
                    downloadFile(unzip, record.contentType, record.fileName)
            }
            catch (error) {
                err = {
                    message: msgType.apiError,
                    detail: error.message,
                    oops: true
                }
            }
        }
        if (err)
            showNotify(err, notifyType.error)

        setCurrentLineFI(null)
        setLastAccess()
    }

    const ccColumns = [
        {
            title: 'Dimensión',
            dataIndex: 'dimName',
            width: 180,
        },
        {
            title: 'Código',
            dataIndex: 'codeName',
        },
    ]

    const fiColumns = [
        {
            title: 'Archivo',
            dataIndex: 'dataOrder',
            render: (_, record) =>
                <div className='flex justify-between'>
                    <div className='flex space-x-1 items-center'>
                        <button
                            className='text-blue-600 text-left'
                            disabled={record.dataOrder === currentLineFI}
                            onClick={() => handleDownFile(record, false)}
                        >
                            {`${record.fileName} `}
                        </button>
                        {
                            record.fileType &&
                            <span>{`- ${record.fileType}`}</span>
                        }
                    </div>
                    <div className='flex justify-end items-center'>
                        {
                            record.dataOrder !== currentLineFI &&
                            <>
                                <button onClick={() => handleDownFile(record, true)}>
                                    <CloudDownloadOutlined />
                                </button>
                            </>
                        }
                        {
                            record.dataOrder === currentLineFI &&
                            <Spin size='small' className='ml-2' />
                        }
                    </div>
                </div>
        },
    ]

    return (
        <>
            {
                openModal &&
                <Modal
                    open={openModal}
                    title="Buscar documento"
                    onOk={() => setOpenModal(false)}
                    onCancel={() => setOpenModal(false)}
                    footer={[]}
                    width={1050}>
                    <ListaDoc selReceptor={selReceptor} selRendicion={selRendicion} showNotify={showNotify} setDocumento={(doc) => { doc && handleChangeDocumento(doc.id, documentos) }} />
                </Modal>
            }
            <div className='flex justify-between mb-2.5 detalledoc-top'>
                <div className='flex space-x-2'>
                    <Select placeholder='Documento' style={{ width: 280 }} value={selDocumento?.documentoID}
                        onChange={(v) => handleChangeDocumento(v, documentos)} >
                        {
                            documentos.map(({ key, documentoID, numDO }) => (
                                <Select.Option key={key} value={documentoID}>{numDO}</Select.Option>
                            ))
                        }
                    </Select>
                    {
                        loadDocumento &&
                        <div className='flex items-center text-indigo-600'>
                            <SyncOutlined spin />
                        </div>
                    }
                    {
                        selRendicion && documentos.length > 0 &&
                        <Tooltip title="Buscar documento" placement='right'>
                            <button onClick={() => setOpenModal(true)} className='flex items-center'>
                                <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                    {
                        totalDoc &&
                        <div className='flex items-center'>
                            <span className='text-xs'>{totalDoc}</span>
                        </div>
                    }
                </div>
            </div>
            <>
                {
                    selDocumento &&
                    <div className='detalledoc-tabs'>
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={activeTabKey}
                            onChange={setActiveTabKey}
                            items={[
                                {
                                    label: `Data`,
                                    key: '1',
                                    children:
                                        <div>
                                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                                                {
                                                    camposDoc.map(({ label, value, detail, validatedEmsr, stdoContrbEmsr, condDomicEmsr, uploadedXML, validatedCPE }, index) => (
                                                        <div className={getClassName(index, camposDoc)} key={index}>
                                                            <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                                <div className="flex items-center space-x-1.5 text-gray-900">
                                                                    <span>{value}</span>
                                                                    {
                                                                        detail &&
                                                                        <span className='text-xs'>{detail}</span>
                                                                    }
                                                                    {
                                                                        validatedEmsr &&
                                                                        <Tooltip title="Emisor validado" placement='left'>
                                                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                                        </Tooltip>
                                                                    }
                                                                    {
                                                                        (stdoContrbEmsr || condDomicEmsr) &&
                                                                        <div className='flex flex-col justify-center' style={{ fontSize: '0.55rem', lineHeight: '0.55rem' }}>
                                                                            <span>{stdoContrbEmsr}</span>
                                                                            <label>{condDomicEmsr}</label>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        uploadedXML &&
                                                                        <div className='flex items-center text-indigo-600'>
                                                                            <Tooltip title="XML cargado" placement='top'>
                                                                                <CloudUploadOutlined />
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        validatedCPE &&
                                                                        <div className='flex items-center pl-0.5 text-indigo-600'>
                                                                            <Tooltip title="Comprobante validado" placement='top'>
                                                                                <ScheduleOutlined />
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </dd>
                                                        </div>
                                                    ))
                                                }
                                            </dl>
                                        </div>
                                },
                                {
                                    label: `Contable`,
                                    key: '2',
                                    children: asignaciones.length > 0 && selAsignacion ?
                                        <>
                                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                                                {
                                                    camposCtb.map(({ label, value, detail }, index) => (
                                                        <div className={getClassName(index, camposCtb)} key={index}>
                                                            <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                                <div className="text-gray-900">
                                                                    <div className="flex items-center space-x-1.5 text-gray-900">
                                                                        <span>{value}</span>
                                                                        {
                                                                            detail &&
                                                                            <span className='text-xs'>{detail}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </div>
                                                    ))
                                                }
                                            </dl>
                                            {
                                                (asignaciones.length > 0 && selAsignacion) &&
                                                <div className='mt-3'>
                                                    <div>
                                                        <Select placeholder='Asignación' style={{ width: 150 }} value={selAsignacion.line} onChange={(v) => handleChangeAsignacion(v, asignaciones)}>
                                                            {
                                                                asignaciones.map(({ key, line, label }) => (
                                                                    <Select.Option key={key} value={line}>{label}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div className='mt-3 ml-1'>
                                                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                                                            {
                                                                camposAsg.map(({ label, value }, index) => (
                                                                    <div className={getClassName(index, camposAsg)} key={index}>
                                                                        <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                                            <div className="text-gray-900">{value}</div>
                                                                        </dd>
                                                                    </div>
                                                                ))
                                                            }
                                                        </dl>
                                                    </div>
                                                    {
                                                        ccosto.length > 0 &&
                                                        <div className='mt-4'>
                                                            <p className="text-xs text-gray-800">
                                                                Centros de costo:
                                                            </p>
                                                            <div className='mt-2 detalledoc-table'>
                                                                <Table
                                                                    columns={ccColumns}
                                                                    dataSource={ccosto}
                                                                    pagination={false}
                                                                    bordered
                                                                    size='small'
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </> :
                                        <span></span>
                                },
                                {
                                    label: `Adjunto`,
                                    key: '3',
                                    disabled: adjuntos.length === 0,
                                    children:
                                        <>
                                            {
                                                adjuntos.length > 0 &&
                                                <div className='mb-3 detalledoc-table'>
                                                    <Table
                                                        columns={fiColumns}
                                                        dataSource={adjuntos}
                                                        pagination={false}
                                                        bordered
                                                        size='small'
                                                    />
                                                </div>
                                            }
                                        </>
                                },
                            ]}
                        />
                    </div>
                }
            </>
        </>
    )
}