import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, ArrowRightOutlined, DeleteFilled, ReloadOutlined } from '@ant-design/icons'
import { Carousel, Checkbox, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { rctGrupoUrl, rctRendicionUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { EditRctTcta } from './EditRctTcta'
import { EditRctGcta } from './EditRctGcta'

const formatosCode = [
    { label: 'Universal', key: 1, code: null },
    { label: 'Anual', key: 2, code: true },
    { label: 'Mensual', key: 3, code: false },
]

export const EditRctEnr = (props) => {
    const { selReceptor, tiposTrib, tiposDoc, monedas, showNotify } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formDta] = Form.useForm()
    const [formSol] = Form.useForm()
    const [formRen] = Form.useForm()
    const carouselRef = useRef()
    const [loadPage, setLoadPage] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [loadGrupo, setLoadGrupo] = useState(false)
    const [loadReceptor, setLoadReceptor] = useState(false)
    const [loadModReceptor, setLoadModReceptor] = useState(false)
    const [receptor, setReceptor] = useState(null)
    const [grupos, setGrupos] = useState([])
    const [tiposSol, setTiposSol] = useState([])
    const [tiposRen, setTiposRen] = useState([])
    const [fmMaxOpenSol, setFmMaxOpenSol] = useState(null)
    const [fmMaxOpenRen, setFmMaxOpenRen] = useState(null)
    const [fmRdoCierre, setFmRdoCierre] = useState(null)
    const [fmRdoDocumento, setFmRdoDocumento] = useState(null)
    const [fmTolTCambio, setFmTolTCambio] = useState(null)
    const [fmTolPorcImp, setFmTolPorcImp] = useState(null)
    const [fmCodeLen, setFmCodeLen] = useState(null)
    const [fmFormatoCode, setFmFormatoCode] = useState(null)
    const [fmEditTCambioRen, setFmEditTCambioRen] = useState(false)
    const [fmEditTCambioOpe, setFmEditTCambioOpe] = useState(false)
    const [fmCtaNacionalOpe, setFmCtaNacionalOpe] = useState(false)
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [fmReqCode_SOL, setFmReqCode_SOL] = useState(null)
    const [fmReqName_SOL, setFmReqName_SOL] = useState(null)
    const [fmRendCode_SOL, setFmRendCode_SOL] = useState(null)
    const [fmCtaCXC_SOL, setFmCtaCXC_SOL] = useState(null)
    const [fmCtaCXP_SOL, setFmCtaCXP_SOL] = useState(null)
    const [fmMultFondo_SOL, setFmMultFondo_SOL] = useState(false)
    const [fmMultRendicion_SOL, setFmMultRendicion_SOL] = useState(false)
    const [fmRendReposicion_SOL, setFmRendReposicion_SOL] = useState(false)
    const [fmRendExceso_SOL, setFmRendExceso_SOL] = useState(false)
    const [fmGrupoCta_SOL, setFmGrupoCta_SOL] = useState([])
    const [fmCCostoAct_SOL, setFmCCostoAct_SOL] = useState(false)
    const [fmRendCode_REN, setFmRendCode_REN] = useState(null)
    const [fmRendName_REN, setFmRendName_REN] = useState(null)
    const [fmCtaCXC_REN, setFmCtaCXC_REN] = useState(null)
    const [fmCtaCXP_REN, setFmCtaCXP_REN] = useState(null)
    const [fmGrupoCta_REN, setFmGrupoCta_REN] = useState([])
    const [selTipoSol, setSelTipoSol] = useState(null)
    const [selTipoRen, setSelTipoRen] = useState(null)
    const [clearSol, setClearSol] = useState(false)
    const [clearRen, setClearRen] = useState(false)
    const [reload, setReload] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado_SOL, setConfirmado_SOL] = useState(true)
    const [confirmado_REN, setConfirmado_REN] = useState(true)
    const [openModalTcta, setOpenModalTcta] = useState(false)
    const [openModalGrp_SOL, setOpenModalGrp_SOL] = useState(false)
    const [openModalGrp_REN, setOpenModalGrp_REN] = useState(false)

    useEffect(() => {
        setLoadPage(loadReceptor || loadGrupo)
    }, [loadReceptor, loadGrupo])

    useEffect(() => {
        setLoadData(loadModReceptor)
    }, [loadModReceptor])

    useEffect(() => {
        const maxOpenSol = receptor?.config?.maxOpenSol || null
        const maxOpenRen = receptor?.config?.maxOpenRen || null
        const rdoCierre = receptor?.config?.rdoCierre || null
        const rdoDocumento = receptor?.config?.rdoDocumento || null
        const tolTCambio = receptor?.config?.tolTCambio || null
        const tolPorcImp = receptor?.config?.tolPorcImp || null
        const codeLen = receptor?.config?.codeLen || null
        const formatoCode = receptor?.config ? formatosCode.find(p => p.code === receptor.config.codeYearMonth) : null
        const editTCambioRen = receptor?.config?.editTCambioRen || false
        const editTCambioOpe = receptor?.config?.editTCambioOpe || false
        const ctaNacionalOpe = receptor?.config?.ctaNacionalOpe || false
        const tipoCta = receptor?.config?.tipoCta || null
        const asol = receptor?.config?.tiposSol?.sort((a, b) => a.reqName > b.reqName ? 1 : -1)?.map((p, i) => getSol(p, i)) || []
        const ssol = asol.find(p => true) || null
        const aren = receptor?.config?.tiposRen?.sort((a, b) => a.rendName > b.rendName ? 1 : -1)?.map((p, i) => getRen(p, i)) || []
        const sren = aren.find(p => true) || null

        setFmMaxOpenSol(maxOpenSol)
        setFmMaxOpenRen(maxOpenRen)
        setFmRdoCierre(rdoCierre)
        setFmRdoDocumento(rdoDocumento)
        setFmTolTCambio(tolTCambio)
        setFmTolPorcImp(tolPorcImp)
        setFmCodeLen(codeLen)
        setFmFormatoCode(formatoCode)
        setFmEditTCambioRen(editTCambioRen)
        setFmEditTCambioOpe(editTCambioOpe)
        setFmCtaNacionalOpe(ctaNacionalOpe)
        setFmTipoCta(tipoCta)
        setTiposSol(asol)
        setTiposRen(aren)
        setSelTipoSol(ssol)
        setSelTipoRen(sren)
        setOpenModalTcta(false)
        setGuardado(true)

        formDta.setFieldsValue({
            maxOpenSol: maxOpenSol,
            maxOpenRen: maxOpenRen,
            rdoCierre: rdoCierre,
            rdoDocumento: rdoDocumento,
            tolTCambio: tolTCambio,
            tolPorcImp: tolPorcImp,
            codeLen: codeLen,
            codeYearMonth: formatoCode?.key,
        })

        if (carouselRef?.current)
            carouselRef.current.goTo(0);
    }, [receptor, formDta])

    useEffect(() => {
        const reqCode = selTipoSol?.reqCode || null
        const reqName = selTipoSol?.reqName || null
        const rendCode = selTipoSol?.rendCode || null
        const ctaCXC = selTipoSol?.tipoCta?.ctaCXC || null
        const ctaCXP = selTipoSol?.tipoCta?.ctaCXP || null
        const multFondo = selTipoSol?.multFondo || false
        const multRendicion = selTipoSol?.multRendicion || false
        const rendReposicion = selTipoSol?.rendReposicion || false
        const rendExceso = selTipoSol?.rendExceso || false
        const ccostoAct = selTipoSol?.ccostoAct || false
        const grupoCta = selTipoSol?.grupos || []

        setFmReqCode_SOL(reqCode)
        setFmReqName_SOL(reqName)
        setFmRendCode_SOL(rendCode)
        setFmCtaCXC_SOL(ctaCXC)
        setFmCtaCXP_SOL(ctaCXP)
        setFmMultFondo_SOL(multFondo)
        setFmMultRendicion_SOL(multRendicion)
        setFmRendReposicion_SOL(rendReposicion)
        setFmRendExceso_SOL(rendExceso)
        setFmCCostoAct_SOL(ccostoAct)
        setFmGrupoCta_SOL(grupoCta)
        setConfirmado_SOL(true)
        setOpenModalGrp_SOL(false)

        formSol.setFieldsValue({
            reqCode: reqCode,
            reqName: reqName,
            rendCode: rendCode,
            ctaCXC: ctaCXC,
            ctaCXP: ctaCXP,
        })
    }, [selTipoSol, grupos, clearSol, formSol])

    useEffect(() => {
        const rendCode = selTipoRen?.rendCode || null
        const rendName = selTipoRen?.rendName || null
        const ctaCXC = selTipoRen?.tipoCta?.ctaCXC || null
        const ctaCXP = selTipoRen?.tipoCta?.ctaCXP || null
        const grupoCta = selTipoRen?.grupos || []

        setFmRendCode_REN(rendCode)
        setFmRendName_REN(rendName)
        setFmCtaCXC_REN(ctaCXC)
        setFmCtaCXP_REN(ctaCXP)
        setFmGrupoCta_REN(grupoCta)
        setConfirmado_REN(true)
        setOpenModalGrp_REN(false)

        formRen.setFieldsValue({
            rendCode: rendCode,
            rendName: rendName,
            ctaCXC: ctaCXC,
            ctaCXP: ctaCXP,
        })
    }, [selTipoRen, grupos, clearRen, formRen])

    useEffect(() => {
        let isMounted = true

        const enrReceptor = async () => {
            isMounted && setLoadReceptor(true)

            const url = rctRendicionUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setReceptor(data.data)
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadReceptor(false)
        }

        const grpReceptor = async () => {
            isMounted && setLoadGrupo(true)

            const url = rctGrupoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setGrupos(data.data?.config?.grupos?.map(p => ({ grpCode: p.grpCode, grpItem: p.grpItem })) || [])
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadGrupo(false)
        }

        if (selReceptor) {
            enrReceptor()
            grpReceptor()
        }
        else {
            setReceptor(null)
            setGrupos([])
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selReceptor, reload])

    // const refreshRct = async (ruc) => {
    //     const url = rctRendicionUrl(ruc)
    //     const [data, err] = await axiosTnaGet(url)

    //     if (err)
    //         console.log(`Receptor: ${err.message}`)
    //     const nrct = data?.success ? data.data : null
    //     return nrct
    // }

    const getSol = (sol, index) => {
        var nsol = { ...sol, key: index, label: `${index + 1}: ${sol.reqName.toUpperCase()}` }
        return nsol
    }

    const getRen = (ren, index) => {
        var nren = { ...ren, key: index, label: `${index + 1}: ${ren.rendName.toUpperCase()}` }
        return nren
    }

    const sortData = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const maxOpenSol = allValues.maxOpenSol || null
        const maxOpenRen = allValues.maxOpenRen || null
        const rdoCierre = allValues.rdoCierre || null
        const rdoDocumento = allValues.rdoDocumento || null
        const tolTCambio = allValues.tolTCambio || null
        const tolPorcImp = allValues.tolPorcImp || null
        const codeLen = allValues.codeLen || null
        const formatoCode = formatosCode.find(p => p.key === allValues.codeYearMonth) || null

        setFmMaxOpenSol(maxOpenSol)
        setFmMaxOpenRen(maxOpenRen)
        setFmRdoCierre(rdoCierre)
        setFmRdoDocumento(rdoDocumento)
        setFmTolTCambio(tolTCambio)
        setFmTolPorcImp(tolPorcImp)
        setFmCodeLen(codeLen)
        setFmFormatoCode(formatoCode)
        setGuardado(false)
    }

    const onValuesChangeSol = (changedValues, allValues) => {
        const reqCode = allValues.reqCode ?? null
        const reqName = allValues.reqName ?? null
        const rendCode = allValues?.rendCode || null
        const ctaCXC = allValues?.ctaCXC || null
        const ctaCXP = allValues?.ctaCXP || null

        setFmReqCode_SOL(reqCode)
        setFmReqName_SOL(reqName)
        setFmRendCode_SOL(rendCode)
        setFmCtaCXC_SOL(ctaCXC)
        setFmCtaCXP_SOL(ctaCXP)
        setConfirmado_SOL(false)
        setGuardado(false)
    }

    const onValuesChangeRen = (changedValues, allValues) => {
        const rendCode = allValues?.rendCode || null
        const rendName = allValues.rendName ?? null
        const ctaCXC = allValues?.ctaCXC || null
        const ctaCXP = allValues?.ctaCXP || null

        setFmRendCode_REN(rendCode)
        setFmRendName_REN(rendName)
        setFmCtaCXC_REN(ctaCXC)
        setFmCtaCXP_REN(ctaCXP)
        setConfirmado_REN(false)
        setGuardado(false)
    }

    const handleChangeGrupo_SOL = (sol, agrp) => {
        const ssol = { ...sol, grupos: (agrp && agrp.length > 0 ? [...agrp] : null), edit: true }
        setTiposSol(prev => {
            const asol = prev.filter(p => p.key !== sol.key)
            const nsol = [...asol, ssol]
            nsol.sort(sortData)
            return nsol
        })
        setSelTipoSol(ssol)
        setGuardado(false)
        setOpenModalGrp_SOL(false)
    }

    const handleChangeGrupo_REN = (ren, agrp) => {
        const sren = { ...ren, grupos: (agrp && agrp.length > 0 ? [...agrp] : null), edit: true }
        setTiposRen(prev => {
            const aren = prev.filter(p => p.key !== ren.key)
            const nren = [...aren, sren]
            nren.sort(sortData)
            return nren
        })
        setSelTipoRen(sren)
        setGuardado(false)
        setOpenModalGrp_REN(false)
    }

    const handleChangeTipoSol = (value, asol) => {
        const ssol = asol.find(p => p.key === value) || null
        setSelTipoSol(ssol)
    }

    const handleChangeTipoRen = (value, aren) => {
        const sren = aren.find(p => p.key === value) || null
        setSelTipoRen(sren)
    }

    const handleChangeTcta = (tipo) => {
        setFmTipoCta(tipo)
        setOpenModalTcta(false)
        setGuardado(false)
    }

    const handleAgregarTipoSol = () => {
        let err = null

        if (!fmReqCode_SOL || fmReqCode_SOL.trim() === '')
            err = {
                message: `${msgType.registroNoField} código de tipo de solicitud.`,
                oops: false
            }
        else if (tiposSol.find(p => p.reqCode === fmReqCode_SOL && (!selTipoSol || selTipoSol.key !== p.key)))
            err = {
                message: `${msgType.rpDataReqCode}`,
                oops: false
            }
        else if (!fmReqName_SOL || fmReqName_SOL.trim() === '')
            err = {
                message: `${msgType.registroNoField} nombre de tipo de solicitud.`,
                oops: false
            }
        else if (!fmRendCode_SOL || fmRendCode_SOL.trim() === '')
            err = {
                message: `${msgType.registroNoField} código de tipo de rendición.`,
                oops: false
            }
        else if (tiposSol.find(p => p.rendCode === fmRendCode_SOL && (!selTipoSol || selTipoSol.key !== p.key)))
            err = {
                message: `${msgType.rpDataRendCode}`,
                oops: false
            }
        else if ((!fmCtaCXC_SOL || fmCtaCXC_SOL.trim() === '') && fmCtaCXP_SOL && fmCtaCXP_SOL.trim() !== '')
            err = {
                message: `${msgType.registroNoField} cuenta por cobrar.`,
                oops: false
            }
        else if ((!fmCtaCXP_SOL || fmCtaCXP_SOL.trim() === '') && fmCtaCXC_SOL && fmCtaCXC_SOL.trim() !== '')
            err = {
                message: `${msgType.registroNoField} cuenta por cobrar.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selTipoSol ? selTipoSol.key : (tiposSol.length > 0 ? Math.max.apply(null, tiposSol.map(p => p.key)) + 1 : 0)
        const ssol = getSol({
            reqCode: fmReqCode_SOL.trim(),
            reqName: fmReqName_SOL.trim(),
            rendCode: fmRendCode_SOL.trim(),
            tipoCta: (fmCtaCXC_SOL || fmCtaCXP_SOL) ? { ctaCXC: fmCtaCXC_SOL, ctaCXP: fmCtaCXP_SOL } : null,
            multFondo: fmMultFondo_SOL,
            multRendicion: fmMultRendicion_SOL,
            rendReposicion: fmRendReposicion_SOL,
            rendExceso: fmRendExceso_SOL,
            ccostoAct: fmCCostoAct_SOL,
            grupos: fmGrupoCta_SOL && fmGrupoCta_SOL.length > 0 ? [...fmGrupoCta_SOL] : null,
        }, maxKey)
        setTiposSol(prev => {
            const asol = selTipoSol ? prev.filter(p => p.key !== selTipoSol.key) : prev.filter(p => true)
            const nsol = [...asol, ssol]
            nsol.sort(sortData)
            return nsol
        })
        setSelTipoSol(ssol)
        setConfirmado_SOL(true)
    }

    const handleQuitarTipoSol = () => {
        if (selTipoSol) {
            const asol = tiposSol.filter(p => p.key !== selTipoSol.key)
            const ssol = asol.find(p => true) || null
            setTiposSol(asol)
            setSelTipoSol(ssol)
            setGuardado(false)
        }
    }

    const handleAgregarTipoRen = () => {
        let err = null

        if (!fmRendCode_REN || fmRendCode_REN.trim() === '')
            err = {
                message: `${msgType.registroNoField} código de tipo de rendición.`,
                oops: false
            }
        else if (tiposRen.find(p => p.rendCode === fmRendCode_REN && (!selTipoRen || selTipoRen.key !== p.key)))
            err = {
                message: `${msgType.rpDataRendCode}`,
                oops: false
            }
        else if (!fmRendName_REN || fmRendName_REN.trim() === '')
            err = {
                message: `${msgType.registroNoField} nombre de tipo de rendición.`,
                oops: false
            }
        else if ((!fmCtaCXC_REN || fmCtaCXC_REN.trim() === '') && fmCtaCXP_REN && fmCtaCXP_REN.trim() !== '')
            err = {
                message: `${msgType.registroNoField} cuenta por cobrar.`,
                oops: false
            }
        else if ((!fmCtaCXP_REN || fmCtaCXP_REN.trim() === '') && fmCtaCXC_REN && fmCtaCXC_REN.trim() !== '')
            err = {
                message: `${msgType.registroNoField} cuenta por cobrar.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selTipoRen ? selTipoRen.key : (tiposRen.length > 0 ? Math.max.apply(null, tiposRen.map(p => p.key)) + 1 : 0)
        const sren = getRen({
            rendCode: fmRendCode_REN.trim(),
            rendName: fmRendName_REN.trim(),
            tipoCta: (fmCtaCXC_REN || fmCtaCXP_REN) ? { ctaCXC: fmCtaCXC_REN, ctaCXP: fmCtaCXP_REN } : null,
            grupos: fmGrupoCta_REN && fmGrupoCta_REN.length > 0 ? [...fmGrupoCta_REN] : null,
        }, maxKey)
        setTiposRen(prev => {
            const aren = selTipoRen ? prev.filter(p => p.key !== selTipoRen.key) : prev.filter(p => true)
            const nren = [...aren, sren]
            nren.sort(sortData)
            return nren
        })
        setSelTipoRen(sren)
        setConfirmado_REN(true)
    }

    const handleQuitarTipoRen = () => {
        if (selTipoRen) {
            const aren = tiposRen.filter(p => p.key !== selTipoRen.key)
            const sren = aren.find(p => true) || null
            setTiposRen(aren)
            setSelTipoRen(sren)
            setGuardado(false)
        }
    }

    const handleModReceptor = async () => {
        let err = null
        let data = null

        if (!receptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (fmMaxOpenSol === undefined || fmMaxOpenSol === null || fmMaxOpenSol <= 0)
            err = {
                message: `${msgType.registroNoField} máximas solicitudes abiertas.`,
                oops: false
            }
        else if (fmMaxOpenRen === undefined || fmMaxOpenRen === null || fmMaxOpenRen <= 0)
            err = {
                message: `${msgType.registroNoField} máximas rendiciones abiertas.`,
                oops: false
            }
        else if (fmRdoCierre === undefined || fmRdoCierre === null || fmRdoCierre < 0)
            err = {
                message: `${msgType.registroNoField} redondeo de cierre.`,
                oops: false
            }
        else if (fmRdoDocumento === undefined || fmRdoDocumento === null || fmRdoDocumento < 0)
            err = {
                message: `${msgType.registroNoField} redondeo de documento.`,
                oops: false
            }
        else if (fmTolTCambio === undefined || fmTolTCambio === null || fmTolTCambio < 0)
            err = {
                message: `${msgType.registroNoField} tolerancia de tipo de cambio.`,
                oops: false
            }
        else if (fmTolPorcImp === undefined || fmTolPorcImp === null || fmTolPorcImp < 0)
            err = {
                message: `${msgType.registroNoField} tolerancia de monto impuesto.`,
                oops: false
            }
        else if (fmCodeLen === undefined || fmCodeLen === null || fmCodeLen <= 0)
            err = {
                message: `${msgType.registroNoField} longitud de códigos.`,
                oops: false
            }
        else if (!fmFormatoCode)
            err = {
                message: `${msgType.registroNoField} formato de código.`,
                oops: false
            }
        else if (!confirmado_SOL)
            err = {
                message: msgType.noConfimTipoSol,
                oops: false
            }
        else if (!confirmado_REN)
            err = {
                message: msgType.noConfimTipoRen,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModReceptor(true)

        const aTiposSol = tiposSol.length > 0 ? tiposSol.map(p => ({
            reqCode: p.reqCode,
            reqName: p.reqName,
            rendCode: p.rendCode,
            multFondo: p.multFondo,
            multRendicion: p.multRendicion,
            rendReposicion: p.rendReposicion,
            rendExceso: p.rendExceso,
            ccostoAct: p.ccostoAct,
            tipoCta: p.tipoCta,
            grupos: p.grupos,
        })) : null
        const aTiposRen = tiposRen.length > 0 ? tiposRen.map(p => ({
            rendCode: p.rendCode,
            rendName: p.rendName,
            tipoCta: p.tipoCta,
            grupos: p.grupos,
        })) : null

        const url = rctRendicionUrl(receptor.id)
        const body = {
            maxOpenSol: fmMaxOpenSol, maxOpenRen: fmMaxOpenRen, rdoCierre: fmRdoCierre, rdoDocumento: fmRdoDocumento,
            tolTCambio: fmTolTCambio, tolPorcImp: fmTolPorcImp, codeLen: fmCodeLen, codeYearMonth: fmFormatoCode.code,
            editTCambioRen: fmEditTCambioRen, editTCambioOpe: fmEditTCambioOpe, ctaNacionalOpe: fmCtaNacionalOpe,
            tiposSol: aTiposSol, tiposRen: aTiposRen, tipoCta: fmTipoCta,
        }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.receptorModificar} ${selReceptor.ruc}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            //setReceptor(await refreshRct(selReceptor.ruc))
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModReceptor(false)
        setLastAccess()
        if (carouselRef?.current)
            carouselRef.current.goTo(0);
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            {
                openModalTcta &&
                <Modal
                    open={openModalTcta}
                    title={"Tipo de cuenta"}
                    onOk={() => setOpenModalTcta(false)}
                    onCancel={() => setOpenModalTcta(false)}
                    footer={[]}
                    width={475}>
                    <EditRctTcta tipoCta={fmTipoCta} tiposTrib={tiposTrib} tiposDoc={tiposDoc} monedas={monedas} setTipoCta={tipo => { tipo && handleChangeTcta(tipo) }} />
                </Modal>
            }
            {
                openModalGrp_SOL &&
                <Modal
                    open={openModalGrp_SOL}
                    title={`Tipo de solicitud (${selTipoSol?.reqCode})`}
                    onOk={() => setOpenModalGrp_SOL(false)}
                    onCancel={() => setOpenModalGrp_SOL(false)}
                    footer={[]}
                    width={500}>
                    <EditRctGcta grupos_sel={fmGrupoCta_SOL} grupos_all={grupos} onCxcCxp={true} setGrupos={param => { param && handleChangeGrupo_SOL(selTipoSol, param) }} />
                </Modal>
            }
            {
                openModalGrp_REN &&
                <Modal
                    open={openModalGrp_REN}
                    title={`Tipo de rendición (${selTipoRen?.rendCode})`}
                    onOk={() => setOpenModalGrp_REN(false)}
                    onCancel={() => setOpenModalGrp_REN(false)}
                    footer={[]}
                    width={500}>
                    <EditRctGcta grupos_sel={fmGrupoCta_REN} grupos_all={grupos} onCxcCxp={true} setGrupos={param => { param && handleChangeGrupo_REN(selTipoRen, param) }} />
                </Modal>
            }
            {
                loadPage &&
                <div className='flex mt-2 w-full justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            <div className={'ant-carousel-titania' + (loadPage ? ' hidden' : '')}>
                <Carousel ref={carouselRef}>
                    <div>
                        <div className='flex items-center justify-center space-x-2'>
                            <span className='text-gray-600 text-xs'>{'Configuración principal'}</span>
                            {
                                !guardado &&
                                <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                            }
                        </div>
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeDta}
                                disabled={loadData}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formDta}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="maxOpenSol"
                                            label="Solicitudes abiertas"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese cantidad máxima'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={5} min={0}
                                                bordered={true} precision={0}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="maxOpenRen"
                                            label="Rendiciones abiertas"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese cantidad máxima'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={5} min={0}
                                                bordered={true} precision={0}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="codeLen"
                                            label="Longitud de código"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese longitud'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={5} min={0}
                                                bordered={true} precision={0}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="codeYearMonth"
                                            label="Formato de código"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Seleccione formato"
                                                allowClear>
                                                {
                                                    formatosCode.map(({ key, label }) => (
                                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="tolTCambio"
                                            label="Tolerancia de cambio"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese monto'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={10} min={0.0}
                                                bordered={true} precision={2}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="tolPorcImp"
                                            label="Tolerancia de impuesto"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese monto'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={10} min={0.0}
                                                bordered={true} precision={2}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rdoCierre"
                                            label="Redondeo de cierre"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese monto'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={10} min={0.0}
                                                bordered={true} precision={2}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rdoDocumento"
                                            label="Redondeo de documento"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder='Ingrese monto'
                                                className='text-xs w-36'
                                                size='middle'
                                                maxLength={10} min={0.0}
                                                bordered={true} precision={2}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='flex space-x-2 mt-1'>
                            <div className='flex flex-col'>
                                <div>
                                    <Checkbox className='text-xs' checked={fmEditTCambioRen} onChange={(e) => { setFmEditTCambioRen(e.target.checked); setGuardado(false) }}>Editar tipo de cambio en rendición</Checkbox>
                                </div>
                                <div>
                                    <Checkbox className='text-xs' checked={fmEditTCambioOpe} onChange={(e) => { setFmEditTCambioOpe(e.target.checked); setGuardado(false) }}>Editar tipo de cambio en operación</Checkbox>
                                </div>
                                <div>
                                    <Checkbox className='text-xs' checked={fmCtaNacionalOpe} onChange={(e) => { setFmCtaNacionalOpe(e.target.checked); setGuardado(false) }}>Cuenta en moneda nacional para toda operación</Checkbox>
                                </div>
                            </div>
                        </div>
                        <div className='flex space-x-2 mt-4 items-center'>
                            <span className='text-blue-600 text-xs'>{'Tipo de cuenta'}</span>
                            <button onClick={() => setOpenModalTcta(true)} className='flex items-center'>
                                <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                            </button>
                            {
                                fmTipoCta &&
                                <Tooltip title="Quitar configuración de cuenta" placement='top'>
                                    <button onClick={() => handleChangeTcta(null)} className='flex items-center'>
                                        <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                        </div>
                        <div className='mb-10'></div>
                    </div>
                    <div>
                        <div className='flex items-center justify-center space-x-2'>
                            <span className='text-gray-600 text-xs'>{'Configuración de tipo de solicitud'}</span>
                            {
                                !guardado &&
                                <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                            }
                        </div>
                        <div className='mt-3'>
                            <div className='mb-1'>
                                <span>Lista de tipos de solicitud</span>
                            </div>
                            <div className='flex space-x-2'>
                                <Select placeholder='Nuevo tipo de solicitud' style={{ width: 280 }} value={selTipoSol?.key}
                                    onChange={(v) => handleChangeTipoSol(v, tiposSol)} disabled={loadData} allowClear>
                                    {
                                        tiposSol.map(({ key, label }) => (
                                            <Select.Option key={key} value={key}>{label}</Select.Option>
                                        ))
                                    }
                                </Select>
                                {
                                    !selTipoSol &&
                                    <Tooltip title="Limpiar tipo de solicitud" placement='top'>
                                        <button onClick={() => setClearSol(prev => !prev)} className='flex items-center'>
                                            <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (!selTipoSol && !confirmado_SOL) &&
                                    <Tooltip title="Nuevo tipo de solicitud" placement='top'>
                                        <button onClick={handleAgregarTipoSol} className='flex items-center'>
                                            <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selTipoSol && !confirmado_SOL) &&
                                    <Tooltip title="Modificar tipo de solicitud" placement='top'>
                                        <button onClick={handleAgregarTipoSol} className='flex items-center'>
                                            <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selTipoSol && confirmado_SOL) &&
                                    <Tooltip title="Quitar tipo de solicitud" placement='top'>
                                        <button onClick={handleQuitarTipoSol} className='flex items-center'>
                                            <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    !confirmado_SOL &&
                                    <div className='flex items-center justify-center'>
                                        <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeSol}
                                disabled={loadData}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formSol}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="reqCode"
                                            label="Código de tipo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese código de tipo'
                                                size='middle'
                                                maxLength={10}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rendCode"
                                            label="Código de rendición"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese código de rendición'
                                                size='middle'
                                                maxLength={10}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="reqName"
                                            label="Nombre de tipo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese nombre de tipo'
                                                size='middle'
                                                maxLength={100}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="ctaCXC"
                                            label="Cuenta por cobrar"
                                            rules={[
                                                {
                                                    required: (!!fmCtaCXP_SOL),
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese número de cuenta'
                                                size='middle'
                                                maxLength={15}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="ctaCXP"
                                            label="Cuenta por pagar"
                                            rules={[
                                                {
                                                    required: (!!fmCtaCXC_SOL),
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese número de cuenta'
                                                size='middle'
                                                maxLength={15}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='flex space-x-2 mt-1'>
                            <div className='flex flex-col'>
                                <div>
                                    <Checkbox className='text-xs' checked={fmMultFondo_SOL} onChange={(e) => { setFmMultFondo_SOL(e.target.checked); setConfirmado_SOL(false); setGuardado(false) }}>Permitir fondos múltiples</Checkbox>
                                </div>
                                <div>
                                    <Checkbox className='text-xs' checked={fmRendExceso_SOL} onChange={(e) => { setFmRendExceso_SOL(e.target.checked); setConfirmado_SOL(false); setGuardado(false) }}>Rendir excediendo el saldo</Checkbox>
                                </div>
                                <div>
                                    <Checkbox className='text-xs' checked={fmCCostoAct_SOL} onChange={(e) => { setFmCCostoAct_SOL(e.target.checked); setConfirmado_SOL(false); setGuardado(false) }}>Activar los centros de costo</Checkbox>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div>
                                    <Checkbox className='text-xs' checked={fmMultRendicion_SOL} onChange={(e) => { setFmMultRendicion_SOL(e.target.checked); setConfirmado_SOL(false); setGuardado(false) }}>Permitir rendiciones múltiples</Checkbox>
                                </div>
                                <div>
                                    <Checkbox className='text-xs' checked={fmRendReposicion_SOL} onChange={(e) => { setFmRendReposicion_SOL(e.target.checked); setConfirmado_SOL(false); setGuardado(false) }}>Toda rendición se debe reponer</Checkbox>
                                </div>
                            </div>
                        </div>
                        {
                            (selTipoSol && confirmado_SOL) &&
                            <div className='flex space-x-2 mt-4 items-center'>
                                <span className='text-blue-600 text-xs'>{'Lista de grupos'}</span>
                                <button onClick={() => setOpenModalGrp_SOL(true)} className='flex items-center'>
                                    <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                                {
                                    (fmGrupoCta_SOL && fmGrupoCta_SOL.length > 0) &&
                                    <Tooltip title="Quitar configuración de grupo" placement='top'>
                                        <button onClick={() => handleChangeGrupo_SOL(selTipoSol, null)} className='flex items-center'>
                                            <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                        }
                        <div className='mb-10'></div>
                    </div>
                    <div>
                        <div className='flex items-center justify-center space-x-2'>
                            <span className='text-gray-600 text-xs'>{'Configuración de tipo de rendición'}</span>
                            {
                                !guardado &&
                                <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                            }
                        </div>
                        <div className='mt-3'>
                            <div className='mb-1'>
                                <span>Lista de tipos de rendición</span>
                            </div>
                            <div className='flex space-x-2'>
                                <Select placeholder='Nuevo tipo de rendición' style={{ width: 280 }} value={selTipoRen?.key}
                                    onChange={(v) => handleChangeTipoRen(v, tiposRen)} disabled={loadData} allowClear>
                                    {
                                        tiposRen.map(({ key, label }) => (
                                            <Select.Option key={key} value={key}>{label}</Select.Option>
                                        ))
                                    }
                                </Select>
                                {
                                    !selTipoRen &&
                                    <Tooltip title="Limpiar tipo de rendición" placement='top'>
                                        <button onClick={() => setClearRen(prev => !prev)} className='flex items-center'>
                                            <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (!selTipoRen && !confirmado_REN) &&
                                    <Tooltip title="Nuevo tipo de rendición" placement='top'>
                                        <button onClick={handleAgregarTipoRen} className='flex items-center'>
                                            <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selTipoRen && !confirmado_REN) &&
                                    <Tooltip title="Modificar tipo de rendición" placement='top'>
                                        <button onClick={handleAgregarTipoRen} className='flex items-center'>
                                            <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selTipoRen && confirmado_REN) &&
                                    <Tooltip title="Quitar tipo de rendición" placement='top'>
                                        <button onClick={handleQuitarTipoRen} className='flex items-center'>
                                            <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    !confirmado_REN &&
                                    <div className='flex items-center justify-center'>
                                        <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeRen}
                                disabled={loadData}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formRen}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rendCode"
                                            label="Código de tipo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese código de tipo'
                                                size='middle'
                                                maxLength={10}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="rendName"
                                            label="Nombre de tipo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese nombre de tipo'
                                                size='middle'
                                                maxLength={100}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="ctaCXC"
                                            label="Cuenta por cobrar"
                                            rules={[
                                                {
                                                    required: (!!fmCtaCXP_REN),
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese número de cuenta'
                                                size='middle'
                                                maxLength={15}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="ctaCXP"
                                            label="Cuenta por pagar"
                                            rules={[
                                                {
                                                    required: (!!fmCtaCXC_REN),
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese número de cuenta'
                                                size='middle'
                                                maxLength={15}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        {
                            (selTipoRen && confirmado_REN) &&
                            <div className='flex space-x-2 mt-1 items-center'>
                                <span className='text-blue-600 text-xs'>{'Lista de grupos'}</span>
                                <button onClick={() => setOpenModalGrp_REN(true)} className='flex items-center'>
                                    <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                                {
                                    (fmGrupoCta_REN && fmGrupoCta_REN.length > 0) &&
                                    <Tooltip title="Quitar configuración de grupo" placement='top'>
                                        <button onClick={() => handleChangeGrupo_REN(selTipoRen, null)} className='flex items-center'>
                                            <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                        }
                        <div className='mb-10'></div>
                    </div>
                </Carousel>
            </div>
            {
                !loadPage &&
                <div className="flex flex-row justify-end space-x-3 mx-1">
                    <div className="flex items-center text-sm text-indigo-700">
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?${!guardado ? ' Configuración sin guardar.' : ''}`}
                            onConfirm={() => setReload(prev => !prev)}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Recargar configuración" placement='top'>
                                    <ReloadOutlined className="mr-1.5 text-green-600" aria-hidden="true" />
                                </Tooltip>
                            </div>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleModReceptor}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Guardar config. de gasto" placement='left'>
                                    <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Guardar
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}
