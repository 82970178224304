import { SearchOutlined, CloudDownloadOutlined, CloseCircleOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Select, Spin, Switch, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { catGetCodUrl, cfgGetDocCpeUrl, cfgGetTaxUrl, ocpGetNumUrl, ocpGetRegcUrl, ocpRecuperarUrl, segConnUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { AppFooter } from '../../../components/AppFooter'
import { LydOcp } from '../../../components/Leyend/LydOcp'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { DetalleOcp } from '../../../components/Panel/Detail/DetalleOcp'
import { DetalleRcp } from '../../../components/Panel/Detail/DetalleRcp'
import { ListRcpReg } from '../../../components/Panel/cpe/RegCPE/ListRcpReg'
import { LoadCpeReg } from '../../../components/Panel/cpe/RegCPE/LoadCpeReg'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'

import './RegistrarCPE.css'

var jszip = require('jszip')

export const RegistrarCPE = ({ page: { code: resource } }) => {
  const { axiosTnaGet } = useAxiosTna()
  const notifyRedRef = useRef()
  const notifyYellowRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
  const [loadTipoOrden, setLoadTipoOrden] = useState(true)
  const [loadTipoDocCpe, setLoadTipoDocCpe] = useState(true)
  const [loadTipoTrib, setLoadTipoTrib] = useState(true)
  const [loadBusqOC, setLoadBusqOC] = useState(false)
  const [emsr, setEmsr] = useState([])
  const [rcpt, setRcpt] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedEmsr, setSelectedEmsr] = useState(null)
  const [selectedRcpt, setSelectedRcpt] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [tiposOrden, setTiposOrden] = useState([])
  const [tiposDocCpe, setTiposDocCpe] = useState([])
  const [tiposTrib, setTiposTrib] = useState([])
  const [foFechaIni, setFoFechaIni] = useState(null)
  const [foFechaFin, setFoFechaFin] = useState(null)
  const [foTipoOrden, setFoTipoOrden] = useState(null)
  const [foPendiente, setFoPendiente] = useState(true)
  const [foSave, setFoSave] = useState(null)
  const [foNumOC, setFoNumOC] = useState(null)
  const [orders, setOrders] = useState([])
  const [ocTotalDocuments, setOcTotalDocuments] = useState(0)
  const [ocPageSize, setOcPageSize] = useState(10)
  const [ocCurrentPage, setOcCurrentPage] = useState(1)
  const [currentNumOC, setCurrentNumOC] = useState('')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedOcp, setSelectedOcp] = useState(null)
  const [selectedRcps, setSelectedRcps] = useState([])
  const [selectedCpe, setSelectedCpe] = useState(null)
  const [selectedRcp, setSelectedRcp] = useState(null)

  useEffect(() => {
    setLoadPage(loadTokenConn || loadRcptEmsr || loadTipoOrden || loadTipoDocCpe || loadTipoTrib)
  }, [loadTokenConn, loadRcptEmsr, loadTipoOrden, loadTipoDocCpe, loadTipoTrib])

  useEffect(() => {
    setDisablePage(loadBusqOC)
  }, [loadBusqOC])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const rcptEmsr = async () => {
      const url = usuRcptEmsrUrl(resource)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setEmsr(data.emsr)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadRcptEmsr(false)
    }

    const catTipoOC = async () => {
      const url = catGetCodUrl(configType.catTipoOrden)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposOrden(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadTipoOrden(false)
    }

    const cfgDocCpe = async () => {
      const url = cfgGetDocCpeUrl()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposDocCpe(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadTipoDocCpe(false)
    }

    const cfgTipoTrib = async () => {
      const url = cfgGetTaxUrl()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposTrib(data.detalle || [])
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadTipoTrib(false)
    }

    if (!loadTokenConn) {
      rcptEmsr()
      catTipoOC()
      cfgDocCpe()
      cfgTipoTrib()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  const sortOcp = (a, b) => {
    if (a.fechaEmision > b.fechaEmision)
      return 1
    if (a.fechaEmision < b.fechaEmision)
      return -1
    if (a.numOC > b.numOC)
      return 1
    else
      return -1
  }

  const getOcp = (oc) => ({ ...oc, key: oc.numOC, file: getFile(oc) })

  const updateOcp = (oc) => {
    if (oc) {
      setOrders(prev => {
        const ocp = getOcp(oc)
        const ocps = prev.filter(p => p.numOC !== oc.numOC)
        const data = [...ocps, ocp]
        data.sort(sortOcp)
        return data
      })
    }
  }

  const getFile = (oc) => {
    const file = oc.attach ? oc.attach[0] || null : null
    return file
  }

  const clearFiltroOC = () => {
    setFoSave(null)
    setOrders([])
    setOcTotalDocuments(0)
  }

  const handleSelectEmsr = useCallback((person) => {
    setSelectedEmsr(person)
    let rcpts = []
    if (person && person.rcpt.length > 0)
      rcpts = person.rcpt
    setRcpt(rcpts)
    clearFiltroOC()
  }, [setSelectedEmsr])

  const handleSelectRcpt = useCallback((person) => {
    setSelectedRcpt(person)
    let grupos = []
    if (person && person.grupo.length > 0)
      grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem }))
    setGroups(grupos)
    clearFiltroOC()
  }, [setSelectedRcpt])

  const handleSelectGroup = useCallback((person) => {
    setSelectedGroup(person)
    clearFiltroOC()
  }, [setSelectedGroup])

  const handleOpenDrawer = useCallback((ocp, rcps, cpe, rcp, accion) => {
    let value = ''
    if (accion === 1)
      value = `Cargar comprobante: ${ocp?.numOC ?? ''}`
    else if (accion === 2)
      value = `Orden de compra: ${ocp?.numOC}`
    else if (accion === 3)
      value = `Recepción de compra: ${rcp?.numRE}`
    setSelectedRcps(rcps)
    setSelectedOcp({ ...ocp, value, accion })
    setSelectedCpe(cpe)
    setSelectedRcp(rcp)
    setOpenDrawer(true)
  }, [setOpenDrawer])

  const handleCloseDrawer = () => {
    setSelectedRcps([])
    setSelectedOcp(null)
    setSelectedCpe(null)
    setSelectedRcp(null)
    setOpenDrawer(false)
  }

  const handleBuscarOCFecha = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.regCpeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.regCpeNoGroup,
        oops: false
      }
    else if (!selectedEmsr)
      err = {
        message: msgType.regCpeNoEmsr,
        oops: false
      }
    else if (!foFechaIni || !foFechaFin)
      err = {
        message: msgType.regCpeNoFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqOC(true)

    const grpCode = selectedRcpt.grpAll ? '' : selectedGroup.ruc
    const fechaIni = moment(foFechaIni).format().split('T')[0]
    const fechaFin = moment(foFechaFin).format().split('T')[0]
    const tipoOrden = foTipoOrden || ''

    const url = ocpGetRegcUrl(selectedRcpt.ruc, grpCode, selectedEmsr.ruc, fechaIni, fechaFin, tipoOrden, foPendiente, 1, ocPageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: ocs } = data
      ocs.sort(sortOcp)
      setOrders(ocs.map(oc => getOcp(oc)))
      setOcTotalDocuments(totalDocuments)
      setOcCurrentPage(1)
      setFoSave({
        svSelectedRcpt: selectedRcpt,
        svGrpCode: grpCode,
        svSelectedEmsr: selectedEmsr,
        svFechaIni: fechaIni,
        svFechaFin: fechaFin,
        svTipoOrden: tipoOrden,
        svFoPendiente: foPendiente,
      })
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqOC(false)
    setLastAccess()
  }

  const handleChangePagOC = async (page, pageSize) => {
    if (!foSave)
      return

    const { svSelectedRcpt, svGrpCode, svSelectedEmsr, svFechaIni, svFechaFin, svTipoOrden, svFoPendiente } = foSave

    let err = null
    let data = null

    setLoadBusqOC(true)

    const url = ocpGetRegcUrl(svSelectedRcpt.ruc, svGrpCode, svSelectedEmsr.ruc, svFechaIni, svFechaFin, svTipoOrden, svFoPendiente, page, pageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: ocs } = data
      ocs.sort(sortOcp)
      setOrders(ocs.map(oc => getOcp(oc)))
      setOcTotalDocuments(totalDocuments)
      setOcCurrentPage(page)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqOC(false)
    setLastAccess()
  }

  const handleBuscarOCNum = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.regCpeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.regCpeNoGroup,
        oops: false
      }
    else if (!selectedEmsr)
      err = {
        message: msgType.regCpeNoEmsr,
        oops: false
      }
    if (!foNumOC || foNumOC.trim().length === 0)
      err = {
        message: msgType.regCpeNoNumOC,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqOC(true)

    const url = ocpGetNumUrl(selectedRcpt.ruc, foNumOC)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { success, data: oc } = data
      if (success && oc.emisor?.ruc === selectedEmsr.ruc) {
        setOrders([getOcp(oc)])
        setOcTotalDocuments(1)
      }
      else {
        setOrders([])
        setOcTotalDocuments(0)
      }
      setFoSave(null)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqOC(false)
    setLastAccess()
  }

  const handleClickNumOC = async (record) => {
    const attach = record.file

    let err = null
    let data = null

    setCurrentNumOC(record.numOC)

    const url = ocpRecuperarUrl(record.id, attach.dataOrder)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(attach.fileName).async('base64')
        if (attach.contentType === configType.contentTypePDF)
          viewerFile(unzip, attach.contentType, attach.fileName)
        else
          downloadFile(unzip, attach.contentType, attach.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentNumOC('')
    setLastAccess()
  }

  const ocColumns = [
    {
      title: 'Nº Documento',
      dataIndex: 'numOC',
      render: (text, record) =>
        <div className='flex items-center'>
          <button
            className='text-blue-600 w-full text-left'
            onClick={() => handleOpenDrawer(record, [], null, null, 2)}
            disabled={record.numOC === currentNumOC}
          >
            {text}
          </button>
          {
            record.file &&
            <div className='flex justify-end'>
              {
                record.numOC !== currentNumOC &&
                <button onClick={() => handleClickNumOC(record)}>
                  <CloudDownloadOutlined />
                </button>
              }
              {
                record.numOC === currentNumOC &&
                <Spin size='small' />
              }
            </div>
          }
        </div>
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaEmision',
      width: 120,
      render: (_, record) => <span>{formatDate(record.fechaEmision)}</span>
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      width: 140,
      render: (text, record) => <span>{text}{record.sufijo || ''}</span>
    },
    {
      title: 'Comprador',
      dataIndex: 'comprador',
      width: 160,
    },
    {
      title: 'Moneda',
      dataIndex: 'monedaISO',
      width: 90,
      align: 'center',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 130,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.total)}</span>
    },
  ]

  return (
    <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
      <Drawer
        title={`${selectedOcp?.value ?? 'Documento'}`}
        placement="right"
        onClose={handleCloseDrawer}
        open={openDrawer}
        getContainer={false}
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 20, }}
        headerStyle={{ padding: 15, }}
        destroyOnClose={true}
        maskClosable={false}
        zIndex={5}
        closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
      >
        {
          (selectedOcp?.accion ?? 1) === 1 ?
            <LoadCpeReg
              rcpt={selectedRcpt} emsr={selectedEmsr}
              ocp={selectedOcp} rcps={selectedRcps} cpe={selectedCpe}
              updateOcp={updateOcp} tiposDocCpe={tiposDocCpe} tiposTrip={tiposTrib}
            /> :
            <>
              {
                (selectedOcp?.accion ?? 1) === 2 ?
                  <DetalleOcp rcpt={selectedRcpt} numOC={selectedOcp?.numOC} /> :
                  <DetalleRcp rcpt={selectedRcpt} numRE={selectedRcp?.numRE} />
              }
            </>
        }
      </Drawer>
      <NotifyRed ref={notifyRedRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='flex-grow'>
            <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
              <SelRcptEmsr comboLabel="EMISOR" people={emsr} setPerson={handleSelectEmsr} flex_basis='basis-96' />
              <SelRcptEmsr comboLabel="RECEPTOR" people={rcpt} setPerson={handleSelectRcpt} flex_basis='basis-96' />
              {
                !(selectedRcpt?.grpAll ?? true) &&
                <SelRcptEmsr comboLabel={selectedRcpt?.grpName ?? 'Grupo'} people={groups} setPerson={handleSelectGroup} flex_basis='basis-72' />
              }
            </div>

            <div className='p-3 flex flex-col ant-common-titania'>
              <p className="text-xs text-gray-700 mb-2">
                Busca una orden de compra usando los filtros de fecha de emisión o número de documento.
              </p>
              <div className='flex space-x-10'>
                <div className='flex items-center space-x-2'>
                  <DatePicker placeholder='Fecha inicio' onChange={(d,) => setFoFechaIni(d?.toDate() ?? null)} />
                  <DatePicker placeholder='Fecha fin' onChange={(d,) => setFoFechaFin(d?.toDate() ?? null)} />
                  <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                    <Select placeholder='Tipo' allowClear style={{ width: 75 }} onChange={(v) => setFoTipoOrden(v || null)}>
                      {
                        tiposOrden.map(({ codigo }) => (
                          <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                        ))
                      }
                    </Select>
                  </div>
                  <Switch checkedChildren="CPE" unCheckedChildren="ALL" checked={foPendiente} onChange={(e) => setFoPendiente(e)} />
                  {
                    !disablePage ?
                      <Tooltip title="Buscar orden de compra">
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarOCFecha} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>
                <div className='flex items-center space-x-2 ant-input-fnregcpe'>
                  <Input placeholder="Nº de documento" onChange={(e) => setFoNumOC(e.target.value)} />
                  {
                    !disablePage ?
                      <Tooltip title="Buscar orden de compra">
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarOCNum} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>
              </div>

              <div className='lg:max-w-4xl'>
                {
                  loadBusqOC ?
                    <div className='my-5 flex justify-center'>
                      <Spin tip='Loading....' size='large' />
                    </div> :
                    <>
                      <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnregcpe'>
                        <Table
                          columns={ocColumns}
                          dataSource={orders}
                          pagination={false}
                          scroll={{ y: 400 }}
                          bordered
                          size='small'
                          expandable={{
                            expandedRowRender: (record) => (
                              <ListRcpReg
                                record={record}
                                selectedRcpt={selectedRcpt}
                                openDrawer={handleOpenDrawer}
                              />)
                          }} />
                      </div>
                      <div className='mt-2 flex justify-end ant-pagination-titania'>
                        <Pagination
                          defaultCurrent={ocCurrentPage}
                          total={ocTotalDocuments}
                          showSizeChanger
                          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} ordenes de compra`}
                          defaultPageSize={ocPageSize}
                          onChange={handleChangePagOC}
                          onShowSizeChange={(_, size) => setOcPageSize(size)} />
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
      <div className='absolute bottom-3 left-3'>
        <LydOcp />
      </div>
    </div>
  )
}
